import AnimatedPage from "../AnimatedPage/AnimatedPage";
import { useContext } from "react";
import { ThemeContext } from "../../modules/ThemeContext/ThemeContext";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import Footer from "../../modules/footer";
import { Helmet } from "react-helmet";
import Quote from "../../modules/quote";

const AboutUs = () => {
  const {theme, setTheme} = useContext(ThemeContext)
  const fontColor = (theme === "dark" ? "rgb(231 229 228)" : "black")
  const headingColor = (theme === "dark" ? "#E7EBF1" : "#584a89")

  const puzzleAnimation = require('../../lottieJsons/puzzle.json')
  const chakPortrait = require("../../img/Chak portrait.png")
  const jiarongPortait = require("../../img/Aow Jia Rong portrait.png")
  const lukasPortrait = require("../../img/Lukas Portrait.png")
  const zhenquanPortait = require("../../img/Zhen Quan portrait.png")
  const tiancongPortrait = require("../../img/TianChong.png")
  const theoPortrait = require("../../img/Theofratos Portrait.png")
  const haninnPortrait = require("../../img/han inn portrait.png")

    return (
      <>
      <Helmet>
        <title>Noggin – About Us</title>
        <meta
          name="description"
          content="We aim to uphold data portability, data democratization, allowing individuals to regain full control of their data. With our capable team at Noggin, our goals are brought to life."
        />
        <meta
            name="keywords"
            content="Noggin Team, Kong Soon Chak, Aow Jia Rong, Lukas Ng, Poh Zhen Quan, Chew Tian Cong, Andreas Theofratos, Ng Han Inn"
        />
      </Helmet>
      <AnimatedPage>
        <style>
          {
           `.pageContent{
              word-break: break-word;
              color : ${fontColor}
            }`
          }
        </style>
        <div className="flex flex-col lg:flex-row items-center mt-10 lg:mt-0" style={{paddingLeft:"10%",paddingTop:"5%",paddingRight:"10%"}}>
          <div className="w-full lg:w-6/12 flex-initial pt-10 2xl:pt-0">
            <div id="about-noggin" className="flex text-center 2xl:text-left flex-row justify-center 2xl:justify-start">
              <h1 style={{fontFamily:"Neutro",color:`${headingColor}`}} className="text-4xl text-stone-200">
              What's Noggin?
            </h1>
            </div>
          <div>
            <div className="flex justify-center 2xl:justify-start">          
                <p style={{fontFamily:"SF Pro"}} className="text-3xl pageContent">
                Our Goals and Aims
                </p>
            </div>
          </div>
          </div>
          <div className="w-full lg:w-2/12 flex-initial"></div>
          <div className="w-full lg:w-4/12 flex-initial flex justify-center">
          <Lottie 
                loop={false}
                animationData={puzzleAnimation} 
                play
                style={{ width: 555, height: 514}}
                />
          </div>
        </div>
        <div style={{paddingLeft:"10%",paddingRight:"10%"}} className=" justify-center flex flex-col lg:flex-row ">
          <div className =" lg:w-3/12 w-full lg:mr-5 my-3 lg:my-0 gradientBgA rounded-xl about-us-card" style={{marginTop:"2%",padding:"20px",paddingTop:"20px"}}>
            <p style={{fontFamily:"SF Pro Bold",paddingRight:"5%",paddingTop:"2%",paddingLeft:"1%",paddingBottom:"15px", color:"#f5f5f5"}} className="text-3xl text-center 2xl:text-left">
              Return Data <br/>To the individual
            </p>
            <p style={{fontFamily:"SF Pro",paddingRight:"5%",paddingBottom:"2%",paddingLeft:"1%", color:"#f5f5f5"}} className="text-md text-center 2xl:text-left">
              Rights to claim and control data, regaining full control of your own data.
            </p>
          </div>
          <div className =" lg:w-3/12 w-full lg:mx-5 my-3 lg:my-0 gradientBgA rounded-xl about-us-card2" style={{marginTop:"2%",padding:"20px",paddingTop:"20px"}}>
            <p style={{fontFamily:"SF Pro Bold",paddingRight:"5%",paddingTop:"2%",paddingLeft:"1%", color:"#f5f5f5",paddingBottom:"15px"}} className="text-3xl  text-center 2xl:text-left">
              Democratizing <br/>Data
            </p>
            <p style={{fontFamily:"SF Pro",paddingRight:"5%",paddingBottom:"2%",paddingLeft:"1%", color:"#f5f5f5"}} className="text-md text-center 2xl:text-left">
              Data democratization is the ongoing process of enabling everybody in an organization, irrespective of their technical know-how, to work with data comfortably, to feel confident talking about it, and, as a result, make data-informed decisions and build customer experiences powered by data.
            </p>
          </div>
          <div className =" lg:w-3/12 w-full lg:ml-5 my-3 lg:my-0 gradientBgA rounded-xl about-us-card" style={{marginTop:"2%",padding:"20px",paddingTop:"20px"}}>
            <p style={{fontFamily:"SF Pro Bold",paddingRight:"5%",paddingTop:"2%",paddingLeft:"1%", color:"#f5f5f5",paddingBottom:"15px"}} className="text-3xl text-center 2xl:text-left">
              Data Portability<br/> is key
            </p>
            <p style={{fontFamily:"SF Pro",paddingRight:"5%",paddingBottom:"15px",paddingLeft:"1%", color:"#f5f5f5"}} className="text-md text-center 2xl:text-left">
              To move the data from the platform/company to the individual
            </p>
            </div>
            <div className =" lg:w-3/12 w-full lg:ml-5 my-3 lg:my-0 gradientBgA rounded-xl about-us-card" style={{marginTop:"2%",padding:"20px",paddingTop:"20px"}}>
            <p style={{fontFamily:"SF Pro Bold",paddingRight:"5%",paddingTop:"2%",paddingLeft:"1%", color:"#f5f5f5",paddingBottom:"15px"}} className="text-3xl text-center 2xl:text-left">
              Insights
            </p>
            <p style={{fontFamily:"SF Pro",paddingRight:"5%",paddingBottom:"15px",paddingLeft:"1%", color:"#f5f5f5"}} className="text-md text-center 2xl:text-left">
            Get business to share insight without sharing their data
            </p>
            </div>
        </div>
        <div id="people-of-noggin" className="flex flex-row justify-center" style={{paddingTop:"5%", color:`${headingColor}`,paddingLeft:"10%",paddingRight:"10%"}}>
          <h1 style={{fontFamily:"Neutro"}} className="text-3xl text-center 2xl:text-left">
            THE PEOPLE OF NOGGIN
          </h1>
        </div>
        <div className="flex flex-row justify-center" style={{paddingTop:"1%", color:`${headingColor}`,paddingLeft:"10%",paddingRight:"10%"}}>
          <p style={{fontFamily:"SF Pro"}} className="text-2xl pageContent">
            The Team
          </p>
        </div>
        <div className="flex-col xl:flex-row flex justify-center items-center" style={{marginTop:"3%", paddingLeft:"10%",paddingRight:"10%"}}>
        <div className="profile-bg flex-initial rounded-lg shadow-lg flex flex-col justify-start w-full xl:w-4/12 text-center my-3 xl:text-left xl:mr-4 xl:ml-4 xl:my-0 height791" style={{backgroundColor:"#f5f5f5",padding:"1% 3% 1.5%",position:"relative"}} >
              <div className="flex flex-row justify-center ">
                <img alt="Chak" src = {chakPortrait} className="justify-center" style={{maxWidth:250,maxHeight:250}}></img>
              </div>
              
              <div>
                <p className = "text-4xl" style={{fontFamily:"SF Pro", paddingTop:"7%", wordBreak:"break-word"}}>Kong Soon <span style={{fontFamily:"SF Pro Bold"}}>Chak</span></p>
              </div>
              
              <div>
                <p style={{fontFamily:"SF Pro", paddingTop:"2%"}} className="text-xl">
                CEO, CO-Founder
                </p>
              </div>
              
              <div>
                <p style={{fontFamily:"SF Pro", paddingTop:"2%",paddingBottom:"3.5%"}} className="text-md">
                Chak Kong Soon is a Managing Partner of Stream Global Pte Ltd and was the President of the Singapore Computer Society. He has been in the Information and Communications Technology (ICT) industry for more than 30 years. His passion in building and nurturing organisations led him to collaborate with several prominent individuals to start Stream Global Pte. Ltd.
                </p>
              </div>
              <div style={{height:25}}></div>
              <div style={{position:"absolute",bottom:"15px",left:"47%",right:"47%"}}>
              <a href="https://www.linkedin.com/in/kong-soon-chak-8740891/" target="_blank" className="content-end">
              <svg
                class="w-6 h-6 text-blue-500 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                ></path>
              </svg>
              </a>
              </div>
            </div>
            <div className="profile-bg2 flex-initial rounded-lg shadow-lg flex flex-col justify-start w-full xl:w-4/12 text-center my-3 xl:text-left xl:mr-4 xl:ml-4 xl:my-0 height791" style={{backgroundColor:"#f5f5f5",padding:"1% 3% 1.5%",position:"relative"}} >
              <div className="flex flex-row justify-center ">
              <img src = {jiarongPortait} className="justify-center" style={{maxWidth:250,maxHeight:250}}></img>
              </div>
              <div>
                <p className = "text-4xl" style={{fontFamily:"SF Pro", paddingTop:"7%", wordBreak:"break-word"}}>Aow <span style={{fontFamily:"SF Pro Bold"}}>Jia Rong</span></p>
              </div>
              <div>
                <p style={{fontFamily:"SF Pro", paddingTop:"2%"}} className="text-xl">
                Solutions Director, Co-Founder
                </p>
              </div>
              <div>
                <p style={{fontFamily:"SF Pro", paddingTop:"2%",paddingBottom:"3.5%"}} className="text-md">
                At the young age of 27, Jia Rong has been recognized and appointed as an Honorary Secretary in SCS's IT Youth Council. He is also part of the IT Advisory Council in Nanyang Polytechnic and the Digital Readiness Network under the MCI's Digital Readiness Council.
Prior to NOGGIN.AI. Jia Rong has delivered multiple projects developing custom enterprise solutions for SMEs in the last decade, including the development and launch of SCS's new website.
                </p>
              </div>
              <div style={{height:25}}></div>
              <div style={{position:"absolute",bottom:"15px",left:"47%",right:"47%"}}>
              <a href="https://www.linkedin.com/in/aowjiarong/" target="_blank">
              <svg
                class="w-6 h-6 text-blue-500 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                ></path>
              </svg>
              </a>
              </div>
            </div>
            <div className="profile-bg3 flex-initial rounded-lg shadow-lg flex flex-col justify-start w-full xl:w-4/12 text-center my-3 xl:text-left xl:mr-4 xl:ml-4 xl:my-0 height791" style={{backgroundColor:"#f5f5f5",padding:"1% 3% 1.5%",position:"relative"}} >
              <div className="flex flex-row justify-center">
              <img src = {lukasPortrait} className="justify-center" style={{maxWidth:250,maxHeight:250}}></img>
              </div>
              <div>
                <p className = "text-4xl" style={{fontFamily:"SF Pro", paddingTop:"7%", wordBreak:"break-word"}}>Lukas <span style={{fontFamily:"SF Pro Bold"}}>Ng</span></p>
              </div>
              <div>
                <p style={{fontFamily:"SF Pro", paddingTop:"2%"}} className="text-xl">
                Director of Operations, CO-Founder
                </p>
              </div>
              <div>
                <p style={{fontFamily:"SF Pro", paddingTop:"2%",paddingBottom:"3.5%"}} className="text-md">
                Establishing and maintaining processes to ensure that the company's objectives are met.
Involved with the Project Management of the company ensuring that the team has clear goals and deadlines. As well as to act as a bridge between the technical and non-technical folks.
                </p>
              </div>
              <div style={{height:25}}></div>
              <div style={{position:"absolute",bottom:"15px",left:"47%",right:"47%"}}>
              <a href="https://www.linkedin.com/in/lukas-ng-567a0b146/" target="_blank">
              <svg
                class="w-6 h-6 text-blue-500 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                ></path>
              </svg>
              </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row" style={{paddingLeft:"10%",paddingTop:"10%",paddingRight:"10%"}}>
            <div className="w-full lg:w-6/12 flex flex-col lg:mr-10">
              <div className="flex-initial mb-6 mt-6">
              <div className="flex justify-start">
                <img src={zhenquanPortait} className="portraits" style={{zIndex:"1",position:"absolute",paddingLeft:"15px"}}></img>
              </div>
              <div className="rounded-lg shadow-lg flex flex-col justify-center peopleDiv" style={{backgroundColor:"#f5f5f5", paddingLeft:"5%",paddingRight:"5%", paddingBottom:"3%"}}>
                <div className = "flex flex-row justify-center">
                <div className="flex-inital w-7/12 lg:w-5/12"></div>
                <div className="flex-initial w-5/12 lg:w-7/12">
                  <p className = "text-3xl" style={{fontFamily:"SF Pro", paddingTop:"5%", wordBreak:"break-word"}}>Poh <span style={{fontFamily:"SF Pro Bold"}}>Zhen Quan</span><br/> <span  className = "text-xl" style={{fontFamily:"SF Pro", paddingTop:"5%", wordBreak:"break-word"}}>Project Manager</span></p>
                </div>
                </div>
                <div className="flex justify-end">
                  <a href ="https://www.linkedin.com/in/kris-poh-zhen-quan-50aa357b/" target="_blank">
                  <svg
                    class="w-6 h-6 text-blue-500 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                  </a>
                </div>
              </div>
              </div>
              <div className="flex-initial mb-6 mt-6">
              <img src={haninnPortrait} className="portraitsHanInn" style={{zIndex:"1",position:"absolute",paddingLeft:"15px"}}></img>
              <div className="rounded-lg shadow-lg flex flex-col justify-center peopleDiv" style={{backgroundColor:"#f5f5f5",paddingLeft:"5%",paddingRight:"5%", paddingBottom:"3%"}}>
                <div className = "flex flex-row justify-center">
                <div className="flex-inital w-7/12 lg:w-5/12"></div>
                <div className="flex-initial w-5/12 lg:w-7/12">
                <p className = "text-3xl" style={{fontFamily:"SF Pro", paddingTop:"5%", wordBreak:"break-word"}}>Ng <span style={{fontFamily:"SF Pro Bold"}}>Han Inn</span><br/> <span  className = "text-xl" style={{fontFamily:"SF Pro", paddingTop:"5%", wordBreak:"break-word"}}>Software Engineer</span></p>
                </div>
                </div>
                <div className="flex justify-end">
                  <a href = "https://www.linkedin.com/in/nghaninn/" target="_blank">
                  <svg
                    class="w-6 h-6 text-blue-500 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                  </a>
                </div>
              </div>
             
              </div>
            </div>
            <div className="w-full lg:w-6/12 flex flex-col lg:ml-10">
            <div className="flex-initial mb-6 mt-6">
              <img src={theoPortrait} className="portraitsTheo" style={{zIndex:"1",position:"absolute",paddingLeft:"15px"}}></img>
              <div className="rounded-lg shadow-lg flex flex-col justify-center peopleDiv" style={{backgroundColor:"#f5f5f5", paddingLeft:"5%",paddingRight:"5%", paddingBottom:"3%"}}>
                <div className = "flex flex-row justify-center">
                <div className="flex-inital w-7/12 lg:w-5/12"></div>
                <div className="flex-initial w-5/12 lg:w-7/12">
                <p className = "text-xl sssm:text-2xl ssm:text-3xl" style={{fontFamily:"SF Pro", paddingTop:"5%", wordBreak:"break-word"}}>Andreas <span style={{fontFamily:"SF Pro Bold"}}>Theofratos</span><br/> <span  className = "text-lg sm:text-3l" style={{fontFamily:"SF Pro", paddingTop:"5%", wordBreak:"break-word"}}>UI UX Designer</span></p>
                </div>
                </div>
                <div className="flex justify-end">
                  <a href = "https://www.linkedin.com/in/theofratos/" target="_blank">
                  <svg
                    class="w-6 h-6 text-blue-500 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                  </a>
                </div>
              </div>
              </div>
              {/* <div className="flex-initial mb-6 mt-6">
              <img src={tiancongPortrait} className="portraitsTianChong" style={{zIndex:"1",position:"absolute",paddingLeft:"15px"}}></img>
              <div className="rounded-lg shadow-lg flex flex-col justify-center peopleDiv" style={{backgroundColor:"#f5f5f5", paddingLeft:"5%",paddingRight:"5%", paddingBottom:"3%"}}>
                <div className = "flex justify-center flex-row">
                <div className="flex-inital w-7/12 lg:w-5/12"></div>
                <div className="flex-initial w-5/12 lg:w-7/12">
                  <p className = "text-3xl" style={{fontFamily:"SF Pro", paddingTop:"5%", wordBreak:"break-word"}}>Chew<span style={{fontFamily:"SF Pro Bold"}}> Tian Cong</span><br/> <span  className = "text-xl" style={{fontFamily:"SF Pro", paddingTop:"5%", wordBreak:"break-word"}}>Software Engineer</span></p>
                  </div>
                </div>
                <div className="flex justify-end">
                  <a href="https://www.linkedin.com/in/tian-cong-cheow-100a7714a/" target="_blank">
                  <svg
                    class="w-6 h-6 text-blue-500 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                  </a>
                </div>
              </div>
              </div> */}
              </div>
          </div>
          <div style={{height:100}}></div>
          <Quote/>
          <Footer/>
      </AnimatedPage>
      </>
    );
  }
  
export default AboutUs;
  