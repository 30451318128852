import AnimatedPage from "../AnimatedPage/AnimatedPage";
import { useContext } from "react";
import { ThemeContext } from "../../modules/ThemeContext/ThemeContext";
import Footer from "../../modules/footer";
import { Helmet } from "react-helmet";
import Quote from "../../modules/quote";


const Users = () => {
  const {theme, setTheme} = useContext(ThemeContext)
  const fontColor = (theme === "dark" ? "rgb(231 229 228)" : "black")
  const headingColor = (theme === "dark" ? "#E7EBF1" : "#584a89")
    return (
      <>
      <Helmet>
        <title>Noggin – For Users</title>
        <meta
          name="description"
          content="With the intent to provide users with the best options, Noggin acts as a personal recommender with top-notch predictive intent and perspective nudge, allowing users to visualise insights."
        />
        <meta
            name="keywords"
            content="Noggin, Descriptive Analytics, Predictive Intent, Perspective Nudge, Personal Recommender"
        />
      </Helmet>
      <AnimatedPage>
        <style>
          {
           `.pageContent{
              word-break: break-word;
              color : ${fontColor}
            }`
          }
        </style>
        <div className = "snap-mandatory snap-y h-screen overflow-scroll maindiv">
        <div style={{color:`${headingColor}`}} className="h-screen pageContent pageHeading text-4xl py-5 snap-center flex justify-center items-center">
              <h1>For Users</h1>
          </div>
          <div className="grid grid-rows-3 snap-center" style={{marginTop:"30%",paddingLeft:"10%",paddingRight:"10%"}}>
            <div className = "row-span-1 grid grid-cols-12" >
              <div className = "col-span-5" style={{borderTop:"1px solid gray",borderRight:"1px solid gray", borderBottom:"1px solid gray"}}>
                <h1 className="pageContent pageHeading text-4xl py-5" style={{paddingTop:"11%", paddingLeft:"5%", color:`${headingColor}`}}>WHAT IS NOGGIN FOR USERS?</h1>
              </div>
              <div className="col-span-7" style={{borderTop:"1px solid gray", borderBottom:"1px solid gray"}}>
                <h1 className= "text-3xl pageContent" style={{fontFamily:"SF Pro Bold", paddingTop:"5%", paddingLeft:"5%"}}>Descriptive Analytics</h1>
                <p className= "text-md pageContent" style={{fontFamily:"SF Pro", paddingTop:"2%", paddingLeft:"5%"}}>Visualise your market insights, user or consumer panels.</p>
              </div>
            </div>
            <div className = "row-span-1 grid grid-cols-12">
            <div className = "col-span-7" style={{borderTop:"1px solid gray",borderRight:"1px solid gray", borderBottom:"1px solid gray"}}>
                <h1 className="pageContent text-3xl" style={{fontFamily:"SF Pro Bold", paddingTop:"5%", paddingLeft:"5%"}}>Predictive Intent</h1>
                <p className= "text-md pageContent" style={{fontFamily:"SF Pro", paddingTop:"2%", paddingLeft:"5%", paddingRight:"5%"}}>Predicts where, when and what customers are likely to be doing</p>
            </div>
              <div className="col-span-5" style={{borderTop:"1px solid gray", borderBottom:"1px solid gray"}}>
                <h1 className= "text-3xl pageContent" style={{fontFamily:"SF Pro Bold", paddingTop:"5%", paddingLeft:"5%", paddingRight:"60%"}}>Perspective Nudge</h1>
                <p className= "text-md pageContent" style={{fontFamily:"SF Pro", paddingTop:"2%", paddingLeft:"5%", paddingRight:"5%"}}>Matches a customer with their preferred stimuli.</p>
              </div>
            </div>
            <div className = "row-span-1 grid grid-cols-12">
            <div className = "col-span-12" style={{borderBottom:"1px solid gray"}}>
                <h1 className="pageContent text-3xl" style={{fontFamily:"SF Pro Bold", paddingTop:"3%", paddingLeft:"3%", paddingRight:"40%"}}>Personal Recommender</h1>
                <p className= "text-md pageContent" style={{fontFamily:"SF Pro", paddingTop:"2%", paddingLeft:"3%", paddingRight:"5%"}}>Offer specific recommendations.</p>
            </div>
            </div>        
          </div>
          <div style={{height:"100px"}}></div>
          <Quote/>
          <div className ="snap-start"><Footer/></div>
          </div>
      </AnimatedPage>
      </>
    );
  }
  
export default Users;
  