import AnimatedPage from "../AnimatedPage/AnimatedPage";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../modules/ThemeContext/ThemeContext";
import Footer from "../../modules/footer";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Quote from "../../modules/quote";

const arrowWhite = require("../../img/arrow-right-circle-white.png")
const arrowLeft = require("../../img/arrow-left-circle-fill.png")
const goBackArrow = require("../../img/arrow-left-circle-fill-gray.png")
const goBackArrowLight = require("../../img/arrow-left-circle-fill-color.png")
const lightPlayIcon = require("../../img/play-filled-white.png")
const darkPlayIcon = require("../../img/play-filled.png")
const howNogginWorksAnimation1 = require('../../lottieJsons/lf30_editor_q8vkip4o.json')
const howNogginWorksAnimation2 = require('../../lottieJsons/92693-loading-line.json')
const howNogginWorksAnimation3 = require('../../lottieJsons/lf30_editor_lvkba3lw.json')
const howNogginWorksAnimation4 = require('../../lottieJsons/81988-dotted-line-big-screen.json')
const howNogginWorksAnimation4DarkMode = require('../../lottieJsons/lf30_editor_nddhizrz.json')
const backgroundLines = require('../../lottieJsons/49600-lines.json')


const HowNogginWorks = () => {
  const {theme, setTheme} = useContext(ThemeContext)
  const fontColor = "rgb(231 229 228)"
  const [click, setClick] = useState(false);

  useEffect(() => {
    window.scroll(0,0)
  }, [click])

    return (
      <>
      <Helmet>
        <title>How does Noggin work?</title>
        <meta
          name="description"
          content="Focused on deploying blocks and contracts that allows the user information to be stored."
        />
        <meta
            name="keywords"
            content="Noggin 3.0, POD, Blocks, Contract"
        />
      </Helmet>
      <AnimatedPage>
        <style>
          {
           `.pageContent{
              word-break: break-word;
              color : ${fontColor}
            }`
          }
        </style>
        <div className="overflow-scroll maindiv bg-howNogginWorks">
        <div className="absolute z-[1] lg:w-[55%] lg:h-[50%] left-0 right-0 mx-auto lg:mt-0 lg:block opacity-[10%] mt-[600px] w-full h-full hidden">
          <Lottie 
              loop
              animationData={backgroundLines} 
              play
              style={{ left: 0, right: 0, position: "absolute"}}
              />
        </div>
        <div>
            <div className="relative lg:-ml-[200px]" style={{marginTop:"5%"}}>
                <div className="ssssm:mt-20 ssssm:p-4 pageContent text-7xl py-5 w-full flex-initial text-left px-1 lg:px-[280px]">
                <p className="text-lg ssssm:text-center md:text-left px-4 md:px-14" style={{paddingTop:"5%",fontFamily:"SF Pro",color:`${fontColor}`}}>
                  For each account/organisation, every unique user <br/>  would be stored as a <span className="font-bold">POD</span> in their records/data. <br/> Each POD has <span className="font-bold">multiple blocks</span>, with 
                  each block <br/> being seen as a <span className="font-bold">dataset/database</span>.
                </p>
                <p className="text-lg ssssm:text-center md:text-left px-8 md:px-14" style={{paddingTop:"2%",fontFamily:"SF Pro",color:`${fontColor}`}}>
                  A <span className="font-bold">contract</span> can be applied to each block,<br/> with each contract determining what <br />
                  information can be <span className="font-bold">stored and retrieved</span> <br/> from the block.
                </p>
                </div>
              </div>
        </div>
        {
          !click && <div className="flex flex-row gap-2 justify-center ssssm:flex-wrap relative md:-mt-[10px] mt-[20px]">
          <div>
            <Lottie 
                loop
                animationData={howNogginWorksAnimation1} 
                play
                style={{ width: 250, height: 150 }}
                />
                  
            <div>
              <div className={"mx-auto shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white"}></div>
              <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white"}></div>
              <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white"}></div>
            </div>
          <Lottie 
              loop
              animationData={howNogginWorksAnimation2} 
              play
              style={{ width: 60, height: 17 }}
              className="rotate-90 ml-2 relative -mt-[20px]"
              />
            </div>
            <div>
              <Lottie 
                  loop
                  animationData={howNogginWorksAnimation1} 
                  play
                  style={{ width: 250, height: 150 }}
                  className="hidden md:block"
                  />
                  <div>
                    <div className={"mx-auto shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                    <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                  </div>
                  <Lottie 
                    loop
                    animationData={howNogginWorksAnimation2} 
                    play
                    style={{ width: 60, height: 17 }}
                    className="rotate-90 ml-2 relative -mt-[8px] hidden md:block"
                    />
            </div>
            
            <div>
            <Lottie 
                loop
                animationData={howNogginWorksAnimation1} 
                play
                style={{ width: 250, height: 150 }}
                className="hidden md:block"
                />
                <div>
                  <div className={"mx-auto shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                  <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                  <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                  <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                  <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                </div>
                <Lottie 
                loop
                animationData={howNogginWorksAnimation2} 
                play
                style={{ width: 60, height: 17 }}
                className="rotate-90 ml-2 relative -mt-[48px] hidden md:block"
                />
          </div>
        </div>
        }
          {
            click && 
            <div className="xl:-mt-[250px]">
              <Lottie 
                  loop
                  animationData={howNogginWorksAnimation3} 
                  play
                  style={{ width: 250, height: 150 }}
                  className="mx-auto mb-8"
                  /> 
                 <div>
                  <Lottie 
                  loop
                  animationData={howNogginWorksAnimation4DarkMode} 
                  play
                  style={{ width: 250, height: 150 }}
                  className="relative -mt-[20px] mx-auto"
                  />
                </div>
                  
            <div className="flex flex-row gap-2 justify-center ssssm:flex-wrap relative mt-[40px]">
              
            <div>
              <Lottie 
                  loop
                  animationData={howNogginWorksAnimation1} 
                  play
                  style={{ width: 250, height: 150 }}
                  />
                    
              <div>
                <div className={"mx-auto shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white"}></div>
                <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white"}></div>
                <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white"}></div>
              </div>
            <Lottie 
                loop
                animationData={howNogginWorksAnimation2} 
                play
                style={{ width: 60, height: 17 }}
                className="rotate-90 ml-2 relative -mt-[20px]"
                />
              </div>
              <div>
                <Lottie 
                    loop
                    animationData={howNogginWorksAnimation1} 
                    play
                    style={{ width: 250, height: 150 }}
                    className="hidden md:block"
                    />
                    <div>
                      <div className={"mx-auto shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                      <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                    </div>
                    <Lottie 
                      loop
                      animationData={howNogginWorksAnimation2} 
                      play
                      style={{ width: 60, height: 17 }}
                      className="rotate-90 ml-2 relative -mt-[8px] hidden md:block"
                      />
              </div>
              
              <div>
              <Lottie 
                  loop
                  animationData={howNogginWorksAnimation1} 
                  play
                  style={{ width: 250, height: 150 }}
                  className="hidden md:block"
                  />
                  <div>
                    <div className={"mx-auto shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                    <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                    <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                    <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                    <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                  </div>
                  <Lottie 
                  loop
                  animationData={howNogginWorksAnimation2} 
                  play
                  style={{ width: 60, height: 17 }}
                  className="rotate-90 ml-2 relative -mt-[48px] hidden md:block"
                  />
            </div>
          </div>
          <div style={{height:"50px"}}></div>
          </div>
          }
          <div className="md:px-6">
            {
              !click && <div className="w-full md:-mt-[40px] lg:-mt-[55px] xl:-mt-[60px] -mt-[50px]">
              <div className="ssssm:mt-20 ssssm:p-4 pageContent text-7xl py-5 w-full flex-initial text-right px-1 lg:px-15 2xl:px-20">
              <p className="text-lg ssssm:text-center md:text-right" style={{fontFamily:"SF Pro",color:`${fontColor}`}}>
                Introducing <span className="font-bold">global contracts.</span> This allows 
                <br/> different accounts/organisation to <span className="font-bold">cross share <br/>
                data</span> using the contracts they have defined.
              </p>
              {
                !click && <img src={lightPlayIcon} className="md:float-right mx-auto mt-2 cursor-pointer w-[35px]" alt="play" onClick={() => setClick(true)} />
              }
              </div>
            </div>
            }
            {
              click && <div className="w-full md:-mt-[40px] lg:-mt-[55px] xl:-mt-[110px] -mt-[100px]">
              <div className="ssssm:mt-20 ssssm:p-4 pageContent text-7xl py-5 w-full flex-initial text-right px-1 lg:px-15 2xl:px-20">
              <p className="text-lg ssssm:text-center md:text-right" style={{fontFamily:"SF Pro",color:`${fontColor}`}}>
                Introducing <span className="font-bold">global contracts.</span> This allows <br/> 
                different accounts/organisation to <span className="font-bold">cross share <br/>
                data</span> using the contracts they have defined.
              </p>
              </div>
            </div>
            }
          </div>
          <div className="flex flex-row items-center md:px-16 py-8">
              <Link to="/">
                <img src={goBackArrow} style={{height:"40px",width:"40px"}} className="ml-4 rotate-180" />
              </Link>
              <Link to="/"><h3 className={"font-bold text-2xl ssssm:w-[80px] w-[80px] h-[55px] text-left ml-4 text-white"} style={{fontFamily:"SF Pro Bold"}}>Go Back</h3></Link>
            </div>
          <div style={{height:"80px"}}></div>
          <Quote/>
          <div className ="snap-start"><Footer/></div>
        </div>
      </AnimatedPage>
      </>
    );
  }
  
export default HowNogginWorks;
  