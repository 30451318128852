import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { ThemeContext } from "../../modules/ThemeContext/ThemeContext";
import Media from "react-media";


const BackgroundAni = () => {
  const {theme, setTheme} = useContext(ThemeContext)
  const fontColor = (theme === "dark" ? "rgb(231 229 228)" : "black")
  const headingColor = (theme === "dark" ? "#E7EBF1" : "#584a89")
  
  let currentTheme = "bgDark"
  if(theme == "dark"){
      currentTheme = "bgDark"
  }else{
      currentTheme = "bg"
  }
    return (
      <>
        <div class={`${currentTheme}`}></div>
        <div class={`${currentTheme} bg2`}></div>
        <div class={`${currentTheme} bg3`}></div>
      </>
    );
  }
  
export default BackgroundAni;
  