import AnimatedPage from "../AnimatedPage/AnimatedPage";
import { useContext, useState } from "react";
import { ThemeContext } from "../../modules/ThemeContext/ThemeContext";
import Footer from "../../modules/footer";
import Media from "react-media";
import { Helmet } from "react-helmet";
import HowNogginWorks from "../HowNogginWorks";
import Lottie from "react-lottie-player";
import Quote from "../../modules/quote";
import { Link } from "react-scroll";

const arrowWhite = require("../../img/arrow-right-circle-white.png")
const arrowLeft = require("../../img/arrow-left-circle-fill.png")

const Developers = () => {
  const {theme, setTheme} = useContext(ThemeContext)
  const [click, setClick] = useState(false);
  const fontColor = (theme === "dark" ? "rgb(231 229 228)" : "black")
  const headingColor = (theme === "dark" ? "#E7EBF1" : "#584a89")
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);
  const headerFont = "text-4xl 2xl:text-5xl";
  const technicalArchitecture = require("../../img/technical-architecture.png")
  const nogginCoreArchitecture = require("../../img/noggin-core-architecture.png")
  const nogginClientArchitecture = require("../../img/noggin-client-architecture.png")
  const podsDiagram = require("../../img/pods-diagram.png")
  const nogginLogo = require("../../img/NogginLogo3.png")
  const onboardingIcon = require("../../img/work-list.png")
  const technicalArchitectureIcon = require("../../img/ruler.png")
  const podStructureIcon = require("../../img/data-encryption.png")
  const podSignaturesIcon = require("../../img/secure.png")
  const podBlocksIcon = require("../../img/server-4.png")
  const dataContractsIcon = require("../../img/file-security.png")
  const securityIcon = require("../../img/noggin security.png")
  const nogginIcon = require("../../img/Noggin Logograph.png")
  const arrowWhite = require("../../img/arrow-right-circle-white.png")
  const arrowLeft = require("../../img/arrow-left-circle-fill.png")
  const lightPlayIcon = require("../../img/play-filled-white.png")
  const backgroundAnimation = require('../../lottieJsons/developer dark.json')
  const backgroundAnimation2 = require('../../lottieJsons/lf30_editor_nj5kbe9d.json')
  const howNogginWorksAnimation1 = require('../../lottieJsons/lf30_editor_q8vkip4o.json')
  const howNogginWorksAnimation2 = require('../../lottieJsons/92693-loading-line.json')
  const howNogginWorksAnimation3 = require('../../lottieJsons/lf30_editor_lvkba3lw.json')
  const howNogginWorksAnimation4 = require('../../lottieJsons/81988-dotted-line-big-screen.json')
  const howNogginWorksAnimation4DarkMode = require('../../lottieJsons/lf30_editor_nddhizrz.json')
  const backgroundLines = require('../../lottieJsons/49600-lines.json')

    return (
      <>
      <Helmet>
        <title>Noggin – For Developers</title>
        <meta
          name="description"
          content="Focused on building and deploying instances of Noggin easily. We plan to deliver core services such as Registry Service, alongside with client services such as POD Service, to provide the developers value."
        />
        <meta
            name="keywords"
            content="Noggin 3.0, POD, Onboarding, Federated Learning Contract, Data Contract"
        />
      </Helmet>
        <style>
          {
           `.pageContent{
              word-break: break-word;
              color : ${fontColor}
            }`
          }
        </style>
        <div class ={"overflow-scroll maindiv " + (theme === "dark" ? "developersBg-dark" : "developersBg-light")}>
          <div style={{paddingLeft:"10%",paddingRight:"10%"}} className="h-screen pageContent pageHeading text-4xl snap-center flex justify-start items-center">
            <h1 style={{fontFamily:"Neutro"}} className={"text-2xl sssm:text-3xl sm:text-4xl md:text-5xl text-left " + (theme === "dark" ? "text-[#F5F5F5]" : "text-[#584A89]")}>Noggin <br/>For <span className="underlined" style={{fontFamily:"Neutro"}}>Developers</span></h1>
          </div>

            <div className="flex flex-col lg:flex-row" style={{paddingLeft:"10%",paddingRight:"10%", marginTop:"10%"}}>
            <div className="bg-white w-full lg:w-1/2 flex flex-col lg:mr-3 my-3 lg:my-0 shadow-2xl" style={{color:"#584a89",padding:"35px",borderRadius:"30px"}}>
              <div className="flex items-center">
                  <img alt="" className="pr-4" style={{ maxHeight:80,maxWidth:70, height:'100%',width:"100%"}}src={nogginIcon}></img>
                  <h1 className="text-4xl ssm:text-5xl" style={{fontFamily:"SF Pro Bold"}}>Noggin<br/>3.0</h1>
                </div>
                <p className="" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",borderBottom:"1px solid lightgray",paddingBottom:"20px"}}>Focus on building a easily scalable, easily deployable noggin</p>
                <p className="" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",borderBottom:"1px solid lightgray",paddingBottom:"20px"}}>Self hosted or Noggin hosted</p>
                <p className="" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px"}}>Integrated client management tools</p>
                <ul style={{borderBottom:"1px solid lightgray",paddingBottom:"20px"}}> 
                  <li style={{marginLeft:10}}>• Single sign on for different client</li>
                  <li style={{marginLeft:10}}>• To instantiate new instance of Noggin easily with a step by step walkthrough</li>
                  <li style={{marginLeft:10}}>• To track usage history</li>
                  <li style={{marginLeft:10}}>• To customize business contracts</li>
                </ul>
                <p className="" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px"}}>Pay as you use...</p>
              </div>
              <div className="gradientBgDef w-full lg:w-1/2 flex flex-col lg:ml-3 my-3 lg:my-0 rounded-2xl shadow-2xl" style={{color:"#f2f2f2",padding:"35px",borderRadius:"30px"}}>
                <div className="flex items-center">
                  <img alt="" className="rounded-xl pr-4" style={{ maxHeight:70,maxWidth:65, height:'100%',width:"100%"}}src={onboardingIcon}></img>
                  <h1 className="text-4xl ssm:text-5xl" style={{fontFamily:"SF Pro Bold"}}>Onboarding</h1>
                </div>
                <p className="" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",borderBottom:"1px solid lightgray",paddingBottom:"20px"}}>Delivers a step by step walkthrough process to guide the client<br/>throughout the onboarding process.</p>  
                <p className="" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px"}}>Once configured, noggin will be built and api endpoints<br/>will be generated.</p>
              </div>
            </div>
            <div className="my-3 lg:my-6" style={{paddingRight:"10%",paddingLeft:"10%"}}>
              <div className="gradientBgDef w-full flex flex-col md:mr-3 shadow-2xl hidden" style={{color:"#f2f2f2",padding:"35px",borderRadius:"30px"}}>
                  <div className="flex items-center">
                      <img alt="" className="rounded-xl pr-4" style={{ maxHeight:70,maxWidth:65, height:'100%',width:"100%"}}src={technicalArchitectureIcon}></img>
                      <h1 className="text-4xl ssm:text-5xl" style={{fontFamily:"SF Pro Bold"}}>Technical Architecture</h1>
                  </div>
                  <button type="button" onClick={() =>{setOpenModal1(true)}}>
                  <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"20px"}}>See Architecture Diagram
                  <img src={arrowWhite} className="ml-3 h-6 w-6"></img>
                  </p></button>
                  
                  {openModal1 && <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                    <div class="fixed z-10 inset-0 overflow-y-auto">
                      <div class="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-2xl transform transition-all sm:my-8 sm:w-8/10">
                          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div class="sm:flex sm:items-start sm:flex-col">
                              <img src={technicalArchitecture} className="w-[1000px]"></img>  
                            </div>
                          </div>
                          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-center">
                          <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() =>{setOpenModal1(false)}}>Close</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}

                  
                  <div className="flex flex-col md:flex-row">
                    <div className="flex flex-col w-full md:w-1/2 mr-6">
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px"}}>The objective is to enable new instances of noggin to be easily deployed,<br/> whenever a new client onboards.</p>
                      <ul style={{borderBottom:"1px solid lightgray",paddingBottom:"15px"}}> 
                        <li style={{marginLeft:10}}>• To achieve this level of scalability, each instance of noggin client<br/> should be independent and decoupled. 
                        <br/>• Datas is hosted at individual client level.</li>
                      </ul>
                      <p className="" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"16px"}}>The usage tracking tools are built on top of logging services,<br/> which can be fetched or pushed into Noggin core, at a periodical timeframe.</p>
                    </div>
                    <div className="flex flex-col w-full md:w-1/2 my-3 md:my-0 md:ml-6">
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px"}}>Fundamentally, each client onboard will be hosting / running a series of <br/>AWS services / microservices (Noggin Client).</p>
                      <ul style={{borderBottom:"1px solid lightgray",paddingBottom:"15px"}}> 
                        <li style={{marginLeft:10}}>• This can be self hosted or noggin hosted.<br/> • Ensures easy deployability <br/>• Individually maintainable, or mass deployable through integrated CI/CD</li>
                      </ul>
                      <p className="" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"16px"}}>The core component (Noggin Core) is the primary intersection for Noggin</p>
                      <ul style={{}}> 
                        <li style={{marginLeft:10}}>• Holds the registry to communicate with different clients. <br/>• Primary access control for Client Dashboard <br/>• Collecting logging data into noggin core, for usage analysis etc. <br/>• Code Deployment setup for CI/CD to update lambda layer</li>
                      </ul>
                    </div>
                  </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row" style={{paddingLeft:"10%",paddingRight:"10%"}}>
              <div className="gradientBgDef w-full lg:w-12/12 flex flex-col my-3 lg:my-0 rounded-2xl shadow-2xl hidden" style={{color:"#f2f2f2",padding:"35px",borderRadius:"30px"}}>
                <h1 className="text-4xl ssm:text-5xl" style={{fontFamily:"SF Pro Bold"}}>Noggin Core<br />Services</h1>
                <div className="flex flex-col md:flex-row">
                    <div className="flex flex-col w-full md:w-1/2 mr-6">
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>Registry Service</p>
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>Dashboard Service</p>
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>Core Deploy (CI/CD)</p>
                    </div>
                    <div className="flex flex-col w-full md:w-1/2 my-3 md:my-0 md:ml-6">
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>Logging Service</p>
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>Vault (Hashicorp Vault)</p>
                    </div>
                  </div>
              </div>
            </div>

            <div className="flex flex-col-reverse lg:flex-row-reverse lg:mt-6 hidden" style={{paddingLeft:"10%",paddingRight:"10%"}}>
              <div style={{backgroundColor:"#f2f2f2",padding:"35px",borderRadius:"30px",fontFamily:"SF Pro Bold",color:"#584a89"}} className="w-full lg:w-4/12 lg:ml-3 flex-inital shadow-2xl">
                <h1 className={`${headerFont}`} style={{fontFamily:"SF Pro Bold"}}>Noggin Client<br/>Architecture<br/>Diagram</h1>
                <button type="button" onClick={() =>{setOpenModal3(true)}}>
                <p className="flex flex-row mt-3" style={{fontFamily:"SF Pro"}}>See Architecture Diagram
                  <img src={arrowLeft} className="ml-3 h-6 w-6"></img>
                  </p></button>
                  
                  {openModal3 && <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                    <div class="fixed z-10 inset-0 overflow-y-auto">
                      <div class="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-2xl transform transition-all sm:my-8 sm:w-8/10">
                          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div class="sm:flex sm:items-start sm:flex-col">
                              <img src={nogginClientArchitecture} className="w-[1000px]"></img>
                            </div>
                          </div>
                          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-center">
                          <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() =>{setOpenModal3(false)}}>Close</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
              <div className="gradientBgDef w-full lg:w-8/12 flex flex-col lg:mr-3 my-3 lg:my-0 rounded-2xl shadow-2xl hidden" style={{color:"#f2f2f2",padding:"35px",borderRadius:"30px"}}>
                <h1 className="text-4xl ssm:text-5xl" style={{fontFamily:"SF Pro Bold"}}>Noggin Client<br />Services</h1>
                <div className="flex flex-col md:flex-row">
                    <div className="flex flex-col w-full md:w-1/2 mr-6">
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>POD Service</p>
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>Federated Learning Service</p>
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>Contract Service (CI/CD)</p>
                    </div>
                    <div className="flex flex-col w-full md:w-1/2 my-3 md:my-0 md:ml-6">
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>Logging Service</p>
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>Vault (Hashicorp Vault)</p>
                    </div>
                  </div>
              </div>
            </div>

            <div className="my-3 lg:my-6" style={{paddingRight:"10%",paddingLeft:"10%"}}>
              <div className="gradientBgDef w-full flex flex-col lg:flex-row items-center md:mr-3 shadow-2xl" style={{color:"#f2f2f2",padding:"35px",borderRadius:"30px"}}>
                <div className="w-full lg:w-4/12 flex-initial">
                  <h1 className="text-4xl ssm:text-5xl" style={{fontFamily:"SF Pro Bold"}}>POD</h1>
                  <button type="button" onClick={() =>{setOpenModal2(true)}}>
                <p className="flex flex-row mt-3" style={{fontFamily:"SF Pro"}}>See Architecture Structure
                  <img src={arrowWhite} className="ml-3 h-6 w-6"></img>
                  </p></button>
                  
                  {openModal2 && <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                    <div class="fixed z-10 inset-0 overflow-y-auto">
                      <div class="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-2xl transform transition-all sm:my-8 sm:w-8/10">
                          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div class="sm:flex sm:items-start sm:flex-col">
                              <img src={nogginCoreArchitecture} className="w-[1000px]"></img>
                            </div>
                          </div>
                          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-center">
                          <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() =>{setOpenModal2(false)}}>Close</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
                </div>
                <div className="flex flex-col w-full lg:w-8/12 flex-initial">
                  <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>A POD can be described as a container of encrypted user’s information.</p>
                  <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>Each POD are Individually encrypted, and contains self identifiable user’s information</p>
                </div>
              </div>
            </div>

            <div style={{paddingLeft:"10%",paddingRight:"10%"}} className=" justify-center flex flex-col lg:flex-row ">
          <div className =" lg:w-4/12 w-full lg:mr-3 my-3 lg:my-0 gradientBgDef rounded-2xl about-us-card shadow-2xl" style={{color:"#f2f2f2",padding:"35px",borderRadius:"30px"}}>
            <div className="flex items-center">
              <img alt="" className="rounded-xl pr-4" style={{ maxHeight:70,maxWidth:65, height:'100%',width:"100%"}}src={podStructureIcon}></img>
              <p style={{fontFamily:"SF Pro Bold",paddingRight:"5%",paddingTop:"2%",paddingBottom:"15px", color:"#f5f5f5"}} className={`${headerFont} text-left`} >
              POD<br/>Structure
              </p>
            </div>
            <p style={{fontFamily:"SF Pro",paddingRight:"5%",paddingBottom:"2%", color:"#f5f5f5"}} className="text-md text-left">
            A Pod consists of Signatures, which are <br/>encrypted identifiable information for a user,<br/> and Blocks, which contain encrypted<br/> application values.
            </p>
            <button type="button" onClick={() =>{setOpenModal4(true)}}>
            <p className="flex flex-row mt-3" style={{fontFamily:"SF Pro"}}>See PODS Diagram
              <img src={arrowWhite} className="ml-3 h-6 w-6"></img>
              </p></button>
              
              {openModal4 && <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div class="fixed z-10 inset-0 overflow-y-auto">
                  <div class="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-2xl transform transition-all sm:my-8 sm:w-8/10">
                      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="sm:flex sm:items-start sm:flex-col">
                          <img src={podsDiagram} className="w-[500px]"></img>
                        </div>
                      </div>
                      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-center">
                      <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() =>{setOpenModal4(false)}}>Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
              
          </div>
          <div className =" lg:w-4/12 w-full lg:mx-3 my-3 lg:my-0 gradientBgDef rounded-2xl about-us-card2 shadow-2xl" style={{color:"#f2f2f2",padding:"35px",borderRadius:"30px"}}>
            <div className="flex items-center">
              <img alt="" className="rounded-xl pr-4" style={{ maxHeight:70,maxWidth:65, height:'100%',width:"100%"}}src={podSignaturesIcon}></img>
              <p style={{fontFamily:"SF Pro Bold",paddingRight:"5%",paddingTop:"2%", color:"#f5f5f5",paddingBottom:"15px"}} className={`${headerFont} text-left`}>
              POD <br/> Signatures
              </p>
            </div>
            <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>A signature is an encrypted value of the user’s <br/> Personal Identifiable Information (PII)</p>
            <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>A client can pre set unlimited numbers of <br/> identifiers for each POD</p>
            <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px"}}>With more signatures, it will be highly useful <br/> for future application’s integration, <br/> e.g. Federated Learning</p>
          </div>
          <div className =" lg:w-4/12 w-full lg:ml-3 my-3 lg:my-0 gradientBgDef rounded-2xl about-us-card shadow-2xl" style={{color:"#f2f2f2",padding:"35px",borderRadius:"30px"}}>
            <div className="flex items-center">
              <img alt="" className="rounded-xl pr-4" style={{ maxHeight:70,maxWidth:65, height:'100%',width:"100%"}}src={podBlocksIcon}></img>
              <p style={{fontFamily:"SF Pro Bold",paddingRight:"5%",paddingTop:"2%", color:"#f5f5f5",paddingBottom:"15px"}} className={`${headerFont} text-left`}>
              POD<br/> Blocks
              </p>
            </div>
            <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>A block is a set of application data <br/> that has been stored and encrypted</p>
            <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>These encrypted data can be used for future <br/> application / analysis</p>
            <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px"}}>A data contract is needed to exercise these blocks</p>
          </div>
        </div>

        <div className="flex flex-col-reverse lg:flex-row-reverse lg:mt-6" style={{paddingLeft:"10%",paddingRight:"10%"}}>
              <div style={{backgroundColor:"#f2f2f2",padding:"35px",borderRadius:"30px",fontFamily:"SF Pro Bold",color:"#584a89"}} className="w-full lg:w-4/12 lg:ml-3 flex-inital shadow-2xl">
                <h1 className={`${headerFont}`} style={{fontFamily:"SF Pro Bold"}}>Federated <br/>Learning <br/>Contract <img src={nogginLogo} className="w-[50%] mt-8"/></h1>
              </div>
              <div className="gradientBgDef w-full lg:w-8/12 flex flex-col lg:mr-3 my-3 lg:my-0 rounded-2xl shadow-2xl" style={{color:"#f2f2f2",padding:"35px",borderRadius:"30px"}}>
                <div className="flex items-center">
                  <img alt="" className="rounded-xl pr-4" style={{ maxHeight:70,maxWidth:65, height:'100%',width:"100%"}}src={dataContractsIcon}></img>
                  <h1 className="text-4xl ssm:text-5xl" style={{fontFamily:"SF Pro Bold"}}>Data Contract</h1>
                </div>
                <div className="flex flex-col md:flex-row">
                    <div className="flex flex-col w-full">
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>A Data Contract is an agreement which consists of a set of rules / attributes which are used<br/>to exercise and access the data in the block. They are either defined by the clients<br/> (the organisation) or the end-users.</p>
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>This set of rules are used to encrypt / decrypt the data in the block</p>
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px"}}>Data Contract will be accessed and exercised during an integration call for a federated learning</p>
                    </div>
                  </div>
              </div>
        </div>
        
        <div style={{paddingLeft:"10%",paddingRight:"10%"}} className="mt-6">
              <div className="gradientBgDef w-full flex flex-col lg:mr-3 my-3 lg:my-0 rounded-2xl shadow-2xl" style={{color:"#f2f2f2",padding:"35px",borderRadius:"30px"}}>
                <div className="flex items-center">
                  <img alt="" className="rounded-xl pr-4" style={{ maxHeight:70,maxWidth:65, height:'100%',width:"100%"}}src={securityIcon}></img>
                  <h1 className="text-4xl ssm:text-5xl" style={{fontFamily:"SF Pro Bold"}}>Data Contract</h1>
                </div>
                  <div className="flex flex-col md:flex-row">
                    <div className="flex flex-col w-full md:w-1/2 mr-6">
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>All incoming API calls are authenticated via Noggin CORE, which then returns <br/> the corresponding authentication token and its endpoint to the incoming API.</p>
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px"}}>With the authenticated token, the Noggin CLIENT will authenticate the user <br/> and return an individualised authentication token.</p>
                    </div>
                    <div className="flex flex-col w-full md:w-1/2 my-3 md:my-0 md:ml-6">
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>Each user’s data is encrypted by their unique set of encryption keys.</p>
                      <p className="flex flex-row" style={{fontFamily:"SF Pro",fontSize:"17px",marginTop:"23px",paddingBottom:"5px",borderBottom:"1px solid lightgray",paddingBottom:"15px"}}>Hence, no single user data are encrypted by the same set of encryption keys</p>
                    </div>
                  </div>
              </div>
        </div>
        <div className="my-3 lg:my-6" style={{paddingRight:"10%",paddingLeft:"10%"}}>
              <div className="bg-howNogginWorks w-full flex flex-col md:mr-3 shadow-2xl" style={{color:"#f2f2f2",padding:"35px",borderRadius:"30px"}}>
                  <div className="flex items-center">
                      <h1 id="how-noggin-works" className="text-4xl ssm:text-5xl" style={{fontFamily:"SF Pro Bold"}}>How Does Noggin Work?</h1>
                  </div>
                  <div>
                  <div className="absolute z-[1] lg:w-[40%] lg:h-[40%] left-0 right-0 mx-auto lg:mt-0 lg:block opacity-[10%] hidden">
                    <Lottie 
                        loop
                        animationData={backgroundLines} 
                        play
                        style={{ left: 0, right: 0, position: "absolute"}}
                        />
                  </div>
            <div className="relative lg:-ml-[280px]">
                <div className="p-4 pageContent text-7xl py-5 w-full flex-initial text-left px-1 lg:px-[280px]">
                <p className="text-lg ssssm:text-center md:text-left" style={{paddingTop:"5%",fontFamily:"SF Pro",color:"rgb(231 229 228)"}}>
                  For each account/organisation, every unique user <br/>  would be stored as a <span className="font-bold">POD</span> in their records/data. <br/> Each POD has <span className="font-bold">multiple blocks</span>, with 
                  each block <br/> being seen as a <span className="font-bold">dataset/database</span>.
                </p>
                <p className="text-lg ssssm:text-center md:text-left" style={{paddingTop:"2%",fontFamily:"SF Pro",color:"rgb(231 229 228)"}}>
                  A <span className="font-bold">contract</span> can be applied to each block,<br/> with each contract determining what <br />
                  information can be <span className="font-bold">stored and retrieved</span> <br/> from the block.
                </p>
                </div>
              </div>
        </div>
        {
          !click && <div className="flex flex-row gap-2 justify-center ssssm:flex-wrap relative md:-mt-[20px] mt-[50px]">
          <div>
            <Lottie 
                loop
                animationData={howNogginWorksAnimation1} 
                play
                style={{ width: 250, height: 150 }}
                />
                  
            <div>
              <div className={"mx-auto shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white"}></div>
              <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white"}></div>
              <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white"}></div>
            </div>
          <Lottie 
              loop
              animationData={howNogginWorksAnimation2} 
              play
              style={{ width: 60, height: 17 }}
              className="rotate-90 ml-2 relative -mt-[20px]"
              />
            </div>
            <div>
              <Lottie 
                  loop
                  animationData={howNogginWorksAnimation1} 
                  play
                  style={{ width: 250, height: 150 }}
                  className="hidden md:block"
                  />
                  <div>
                    <div className={"mx-auto shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                    <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                  </div>
                  <Lottie 
                    loop
                    animationData={howNogginWorksAnimation2} 
                    play
                    style={{ width: 60, height: 17 }}
                    className="rotate-90 ml-2 relative -mt-[8px] hidden md:block"
                    />
            </div>
            
            <div>
            <Lottie 
                loop
                animationData={howNogginWorksAnimation1} 
                play
                style={{ width: 250, height: 150 }}
                className="hidden md:block"
                />
                <div>
                  <div className={"mx-auto shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                  <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                  <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                  <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                  <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                </div>
                <Lottie 
                loop
                animationData={howNogginWorksAnimation2} 
                play
                style={{ width: 60, height: 17 }}
                className="rotate-90 ml-2 relative -mt-[48px] hidden md:block"
                />
          </div>
        </div>
        }
          {
            click && 
            <div className="xl:-mt-[280px]">
              <Lottie 
                  loop
                  animationData={howNogginWorksAnimation3} 
                  play
                  style={{ width: 250, height: 150 }}
                  className="mx-auto mb-8"
                  /> 
                 <div>
                  <Lottie 
                  loop
                  animationData={howNogginWorksAnimation4DarkMode} 
                  play
                  style={{ width: 250, height: 150 }}
                  className="relative -mt-[20px] mx-auto"
                  />
                </div>
                  
            <div className="flex flex-row gap-2 justify-center ssssm:flex-wrap relative mt-[40px]">
              
            <div>
              <Lottie 
                  loop
                  animationData={howNogginWorksAnimation1} 
                  play
                  style={{ width: 250, height: 150 }}
                  />
                    
              <div>
                <div className={"mx-auto shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white"}></div>
                <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white"}></div>
                <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white"}></div>
              </div>
            <Lottie 
                loop
                animationData={howNogginWorksAnimation2} 
                play
                style={{ width: 60, height: 17 }}
                className="rotate-90 ml-2 relative -mt-[20px]"
                />
              </div>
              <div>
                <Lottie 
                    loop
                    animationData={howNogginWorksAnimation1} 
                    play
                    style={{ width: 250, height: 150 }}
                    className="hidden md:block"
                    />
                    <div>
                      <div className={"mx-auto shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                      <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                    </div>
                    <Lottie 
                      loop
                      animationData={howNogginWorksAnimation2} 
                      play
                      style={{ width: 60, height: 17 }}
                      className="rotate-90 ml-2 relative -mt-[8px] hidden md:block"
                      />
              </div>
              
              <div>
              <Lottie 
                  loop
                  animationData={howNogginWorksAnimation1} 
                  play
                  style={{ width: 250, height: 150 }}
                  className="hidden md:block"
                  />
                  <div>
                    <div className={"mx-auto shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                    <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                    <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                    <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                    <div className={"mx-auto mt-1 shadow-sm rounded-[5px] w-[146px] h-[10px] bg-white hidden md:block"}></div>
                  </div>
                  <Lottie 
                  loop
                  animationData={howNogginWorksAnimation2} 
                  play
                  style={{ width: 60, height: 17 }}
                  className="rotate-90 ml-2 relative -mt-[48px] hidden md:block"
                  />
            </div>
          </div>
          <div style={{height:"50px"}}></div>
          </div>
          }
          <div>
            {
              !click && <div className="w-full md:-mt-[40px] lg:-mt-[55px] xl:-mt-[40px]">
              <div className="ssssm:mt-20 ssssm:p-4 pageContent text-7xl py-5 w-full flex-initial text-right px-1 lg:px-2">
              <p className="text-lg ssssm:text-center md:text-right" style={{fontFamily:"SF Pro",color:"rgb(231 229 228)"}}>
                Introducing <span className="font-bold">global contracts.</span> This allows 
                <br/> different accounts/organisation to <span className="font-bold">cross share <br/>
                data</span> using the contracts they have defined.
              </p>
              {
                !click && <Link to="how-noggin-works" smooth={true} offset={50} duration={500}><img src={lightPlayIcon} className="md:float-right mx-auto mt-2 cursor-pointer w-[35px]" alt="play" onClick={() => setClick(true)} /></Link>
              }
              </div>
            </div>
            }
            {
              click && <div className="w-full md:-mt-[40px] lg:-mt-[55px] xl:-mt-[100px] -mt-[90px]">
              <div className="ssssm:mt-20 ssssm:p-4 pageContent text-7xl py-5 w-full flex-initial text-right px-1 lg:px-2">
              <p className="text-lg ssssm:text-center md:text-right" style={{fontFamily:"SF Pro",color:"rgb(231 229 228)"}}>
                Introducing <span className="font-bold">global contracts.</span> This allows 
                <br/> different accounts/organisation to <span className="font-bold">cross share <br/>
                data</span> using the contracts they have defined.
              </p>
              </div>
            </div>
            }
          </div>
              </div>
            </div>
          <div style={{height:"100px"}}></div>
          <Quote/>
          <div className ="snap-start"><Footer/></div>
          </div>
      </>
    );
  }
  
export default Developers;
  