import {React, useContext} from "react";
import { ThemeContext } from "../../modules/ThemeContext/ThemeContext";

const Quote = () => {
    const {theme, setTheme} = useContext(ThemeContext)
    const fontColor = (theme === "dark" ? "rgb(231 229 228)" : "black")
    const headingColor = (theme === "dark" ? "#E7EBF1" : "#584a89")
    return (
        <>
            <style>
            {
            `.pageContent{
                word-break: break-word;
                color : ${fontColor}
                }`
            }
            </style>
            <div style={{height:"100px"}}></div>
            <div className="snap-center flex justify-center items-center lg:flex-row flex-col w-full quoteBg" style={{paddingLeft:"10%",paddingRight:"10%",paddingTop:"100px",paddingBottom:"100px"}}>
            <div style={{fontFamily:"SF Pro Bold"}} className="pageContent text-5xl flex justify-center items-center w-full lg:w-4/12">
            <h1 style={{color:`#f2f2f2`,fontSize:"43px"}} className="text-center xl:text-left lg:w-full mb-10 lg:mb-0">Contact Us<br/> To Get A Quote</h1>
            </div>
            <div className="w-1/12"></div>
            <div className="w-full md:w-12/12 lg:w-6/12 xl:w-3/12 flex-initial shadow-lg mb-3 lg:mb-0 lg:mx-3 gradientBg rounded-xl" style={{height:400}} >
                <h1 style={{fontFamily:"SF Pro Bold", paddingLeft:"7%", paddingTop:"7%", wordBreak:"break-word",color:"#f2f2f2"}} className="text-4xl">
                    Noggin<br/> Hosted
                </h1>
                <p style={{fontFamily:"SF Pro",paddingLeft:"7%", paddingTop:"10%", paddingRight:"5%",paddingBottom:"20%",color:"#f2f2f2"}} className="text-lg">
                Have your services hosted with us, on a worry free billing, pay as you use model. 
                <br/>
                A dedicated centralised management tool to view your usage and billing.
                </p>
            </div>
            <div className="w-full md:w-12/12 lg:w-6/12 xl:w-3/12 flex-initial shadow-lg mb-3 mt-3 xl:mt-0 lg:mb-0 lg:mx-3 lg:mt-0 gradientBg rounded-xl" style={{height:400}} >
                <h1 style={{fontFamily:"SF Pro Bold", paddingLeft:"7%", paddingTop:"7%", wordBreak:"break-word",color:"#f2f2f2"}} className="text-4xl">
                    Self
                    <br/>Hosted
                </h1>
                <p style={{fontFamily:"SF Pro",paddingLeft:"7%", paddingTop:"10%", paddingRight:"5%",paddingBottom:"20%",color:"#f2f2f2"}} className="text-lg">
                Have your services hosted on your AWS account, where you will have full control access rights to the project.<br/><br/>Recommended for high volume use.
                </p>
            </div>
            </div>
        </>
    )
}
export default Quote;