import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Particle from './modules/Particles/Particle';
import { ThemeContext } from './modules/ThemeContext/ThemeContext';
import Home from './pages/Home';
import Navbar from './modules/navbar';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Business from './pages/Business';
import Developers from './pages/Developers';
import Users from './pages/Users';
import BackgroundAni from './modules/backgroundAni';
import { BrowserRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import ScrollToTop from './modules/ScrollToTop';
import HowNogginWorks from './pages/HowNogginWorks';

const routes =
[
  {
    path : "/",
    main : Home,
  },
  {
    path : "/about-us",
    main : AboutUs,
  },
  {
    path : "/contact-us",
    main : ContactUs,
  },
  {
    path : "/business",
    main : Business,
  },
  {
    path : "/developers",
    main : Developers,
  },
  {
    path : "/users",
    main : Users,
  },
  {
    path : "/how-noggin-works",
    main : HowNogginWorks,
  },
]
const backgroundDark = require('./lottieJsons/business dark.json')
const backgroundLight = require('./lottieJsons/dev bg light.json')


function App() {
  const [theme, setTheme] = useState("light")
  const today = new Date();



  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  let currentTheme = "backgroundDark"
  if(theme == "dark"){
      currentTheme = "backgroundDark"
  }else{
      currentTheme = "backgroundLight"
  }

  const backgroundImage = (theme === "dark" ? `` : `backgroundLight`)
  return (
    <BrowserRouter>
    <ScrollToTop />
    <ThemeContext.Provider value={{theme, setTheme}}>
      <BackgroundAni/>
      <Navbar />
          <Routes>
            {routes
              .map((route, index) => (
                <Route key={index} path={route.path} element={<route.main/>}>
                </Route>
              ))
            }
          </Routes>
    </ThemeContext.Provider>
    </BrowserRouter>
  );
}

export default App;
