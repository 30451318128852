import AnimatedPage from "../AnimatedPage/AnimatedPage";
import { useContext } from "react";
import { ThemeContext } from "../../modules/ThemeContext/ThemeContext";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { Link } from "react-router-dom";
import Media from "react-media";
import Footer from "../../modules/footer";
import { Helmet } from "react-helmet";
import { ReactComponent as PODSsvg } from '../../img/pods-title.svg';
import Quote from "../../modules/quote";


const businessTeam = require('../../lottieJsons/39387-business-team.json')
const developerAnimation = require('../../lottieJsons/96143-developer.json')
const userAnimation = require('../../lottieJsons/103206-people.json')
const screenAnimation = require('../../lottieJsons/84824-vs-artificial-intelligence.json')
const workHard = require('../../lottieJsons/87979-work-hard.json')
const learnMoreArrow = require("../../img/arrow-left-circle-fill.png")
const aegisLogo = require("../../img/4fe8d41eedf94cfde97d27d614d5e756.png")
const ihhLogo = require("../../img/ihh logo.png")
const simLogo = require("../../img/SIM Logo.png")
const starhubLogo = require("../../img/Mask Group 4.png")
const nanyangLogo = require("../../img/nanyang logo.png")
const scsLogo = require("../../img/Layer 46 copy.png")
const amazonLogo = require("../../img/Image 4.png")
const fortiedgeLogo = require("../../img/Image 5.png")
const nucleoLogo = require("../../img/Image 3.png")
const iminicodeLogo = require("../../img/inimicode_.png")
const seedVenturesLogo = require("../../img/seed ventures.png")
const barworksLogo = require("../../img/barworks logo.png")
const kimLogo = require("../../img/kim logo.png")
const ourLogo = require("../../img/our logo.png")
const learnHowNogginWorksArrowWhenLightMode = require("../../img/arrow-left-circle-fill-color.png")
const learnHowNogginWorksArrowWhenDarkMode = require("../../img/arrow-left-circle-fill-gray.png")

const Home = () => {
  const {theme, setTheme} = useContext(ThemeContext)
  const fontColor = (theme === "dark" ? "rgb(231 229 228)" : "black")
  const headingColor = (theme === "dark" ? "#E7EBF1" : "#584a89")
  const headingFont = " text-2xl sssm:text-3xl sm:text-4xl xl:text-4xl 2xl:text-5xl "
  var arrowChange = ""
  if (theme == "dark") {
    arrowChange = true
  }
  else {
    arrowChange = false
  }
  window.addEventListener('scroll', ()=>{
  })

    return (
      <>
      <Helmet>
        <title>Noggin – Collective Intelligence</title>
        <meta
          name="description"
          content="Noggin.ai, where data is knowledge and knowledge is power, is a collective intelligence tech platform that collaboratively produces smart data for you."
        />
        <meta
            name="keywords"
            content="Noggin, Data, PODS, Noggin Web 3.0, Security Encryption, PDPA/GPDR Compliance"
        />
      </Helmet>
      <AnimatedPage>
        <style>
          {
          `.pageContent{
              word-break: break-word;
              color : ${fontColor}
            }
            
            @media only screen and (max-width: 450px) {
              .categoryBoxes{
                max-width:350px!important;
                min-width:350px!important;
              }
            }
            
            @media only screen and (min-width: 450px) {
              .categoryBoxes{
                max-width:387.5px!important;
                width:100%!important;
              }
            }

            @media only screen and (min-width: 1300px) {
              .categoryBoxes{
                max-width:421px!important;
                width:100%!imporant;
              }
            }

            `
                    
          }
        </style>
          <div className="flex flex-col lg:flex-row" style={{paddingLeft:"10%",paddingRight:"10%",paddingTop:"50px"}}>
            <div className="flex-initial flex flex-col w-full lg:w-1/2 pt-4 ssm:pt-0">
              <h1 className={"pageContent pageHeading" + headingFont} style={{paddingTop:"15%", color:`${headingColor}`}}>DATA IS
              <br/>
              <span className={"underlined pageContent pageHeading" + headingFont} style={{color:`${headingColor}`}}>KNOWLEDGE</span>
              <br/>
              <span className={"pageContent pageHeading" + headingFont} style={{color:`${headingColor}`}}>AND KNOWLEDGE</span>
              <br/> 
              <span className={"pageContent pageHeading" + headingFont} style={{color:`${headingColor}`}}>IS
                <span className={"underlined pageContent pageHeading" + headingFont} style={{color:`${headingColor}`}}> POWER.</span></span>
              </h1>
              <p className = "pageContent mt-5" style={{fontFamily:"SF Pro"}}>Many organisations understand the importance of data acquisition, or more specifically the accessibility to the right data. Using it with AI to discover the past and anticipate the future. But for most times, we struggle to make sense of it or we find the lack of it.</p>
            </div>
            <div className="flex-initial flex justify-center items-center w-full lg:w-1/2">
            <Lottie 
                loop
                animationData={workHard} 
                play
                className="w-8/12 lg:w-9/12" style={{float:"right"}}
                />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-center items-center" style={{marginTop:"5%", paddingLeft:"10%",paddingRight:"10%",width:"100%"}}>
            <div className="flex-auto rounded-2xl shadow-lg business-bg categoryBoxes justify-center flex flex-col" style={{backgroundColor:"#f5f5f5",marginRight:"2%",maxHeight:"396px",minHeight:"396px"}} >
              <Link to="../business"> 
                <h1 style={{fontFamily:"SF Pro Bold", paddingLeft:"7%", paddingTop:"10%", wordBreak:"break-word"}} className="text-4xl">
                  Noggin For <br/>Business
                </h1>
                <p style={{fontFamily:"SF Pro",paddingLeft:"7%", paddingTop:"2%"}} className="text-lg">
                
                Enable user insight for your organisation.  
                
                </p>
                <p style={{fontFamily:"SF Pro Bold",paddingLeft:"7%",paddingTop:"2%", color:"#584a89"}} className = "text-md flex flex-row">Learn more <img src={learnMoreArrow} className="ml-3 mb-1" style={{height:"25px",width:"25px"}}></img></p>
                <div className="flex flex-row justify-center"><Lottie 
                loop
                animationData={businessTeam} 
                play
                style={{ width: 250, height: 200 }}
                /></div></Link>
            </div>
            <div className="flex-initial" style={{width:"10%",height:"50px"}}></div>
            <div className="flex-auto rounded-2xl shadow-lg dev-bg categoryBoxes justify-center flex flex-col" style={{backgroundColor:"#f5f5f5",maxHeight:"396px",marginRight:"2%", minHeight:"396px"}} >
              <Link to="../developers">
                <h1 style={{fontFamily:"SF Pro Bold", paddingLeft:"7%", paddingTop:"10%", wordBreak:"break-word"}} className="text-4xl">
                  Noggin For <br/>Developers
                </h1>
                <p style={{fontFamily:"SF Pro",paddingLeft:"7%",paddingRight:"7%", paddingTop:"2%"}} className="text-lg">
                Seamless intergration to your architechture.              </p>
                <p style={{fontFamily:"SF Pro Bold",paddingLeft:"7%",paddingTop:"2%", color:"#584a89"}} className = "text-md flex flex-row">Learn more <img src={learnMoreArrow} className="ml-3 mb-1" style={{height:"25px",width:"25px"}}></img></p>
                <div className="flex flex-row justify-center" style={{paddingBottom:10}}>
                <Lottie   
                loop
                animationData={developerAnimation} 
                play
                style={{ width: 250, height: 200 }}
                /></div></Link>
            </div>
            <div className="flex-initial" style={{width:"15%",height:"50px"}}></div>
            <div className="flex-auto rounded-2xl shadow-lg users-bg categoryBoxes justify-center flex flex-col" style={{backgroundColor:"#C1C1C1",maxHeight:"396px",marginRight:"2%", pointerEvents:"none", minHeight:"396px"}} >
              <Link to=".">
                <h1 style={{fontFamily:"SF Pro Bold", paddingLeft:"7%", paddingTop:"10%", wordBreak:"break-word"}} className="text-4xl">
                  Noggin For <br/>Users
                </h1>
                <p style={{fontFamily:"SF Pro",paddingLeft:"7%",paddingRight:"7%", paddingTop:"2%"}} className="text-lg">
                Visualize your market insights.
                </p>
                <p className="text-md flex flex-row pt-12"></p>
                {/* <p style={{fontFamily:"SF Pro Bold",paddingLeft:"7%",paddingTop:"2%", color:"#584a89"}} className = "text-md flex flex-row">Learn more <img src={learnMoreArrow} className="ml-3 mb-1" style={{height:"25px",width:"25px"}}></img></p> */}
                <div className="flex flex-row justify-center">
                <Lottie 
                loop
                animationData={userAnimation} 
                play
                style={{ width: 250, height: 200 }}
                /></div></Link>
            </div>
          </div>
          <div>
          <div className="flex flex-row float-right md:mr-[280px] lg:mr-[330px] items-center lg:mt-[100px] md:mt-[70px] sm:mt-[50px] sm:mr-[100px] ssssm:mr-[80px] ssssm:mt-[50px]">
              <Link to="../how-noggin-works"><h3 className={"font-bold ssssm:text-2xl text-xl w-[160px] h-[55px] text-right " + (theme === "dark" ? "text-white" : "text-[#584A89]")} style={{fontFamily:"SF Pro Bold"}}>Learn How Noggin Works</h3></Link>
              <Link to="../how-noggin-works">
                {/* arrow in light mode */}
                {!arrowChange && <img src={learnHowNogginWorksArrowWhenLightMode} style={{height:"40px",width:"40px"}} className="ml-4" />}
                {/* arrow in dark mode */}
                {arrowChange && <img src={learnHowNogginWorksArrowWhenDarkMode} style={{height:"40px",width:"40px"}} className="ml-4" />}
              </Link>
            </div>
          </div>
          <div><div style={{width:"15%",height:"70px"}} className="sm:m-4 ssssm:m-12"></div></div>
          <div>
            <div className="flex flex-col snap-center flex items-center" style={{paddingLeft:"10%", marginTop:"10%",paddingRight:"10%"}}>
                {/* <div style={{paddingTop:"5%", fontFamily:"SF Pro Heavy"}} className="pageContent py-5 w-full flex-initial text-center">
                  <h1 className="contentHeader text-center gradientText font-bold" style={{lineHeight:"65px",fontSize:"80px"}}><span>PODS</span><br/>
                  <span className="text-5xl xl:text-6xl gradientText font-bold">
                    (Privately Owned Data)
                  </span>
                  </h1>
                </div> */}
                <div className="mx-auto py-5">
                  <PODSsvg className="w-full h-full "/>
                </div>
                <div className="pageContent text-7xl py-5 w-full flex-initial text-center px-1 lg:px-15 2xl:px-80">
                <p className="sm:text-2xl text-xl" style={{fontFamily:"SF Pro",color:`${fontColor}`}}>A <span style={{fontFamily:"SF Pro Bold"}}>POD</span> can be described as a 
                <span style={{fontFamily:"SF Pro Bold"}}> container of encrypted user's information. </span>
                Each POD is individually encrypted, and contains self identifiable user's information,
                they consist of Signatures, which are encrypted identifiable information
                for a user,
                and blocks, which contains encrypted application values.
                </p>
                </div>
              </div>
            </div>
          <Media query = {{ maxWidth: 1519 }}>
            <div id="why-noggin" className = "flex flex-col justify-center" style={{paddingLeft:"10%",paddingRight:"10%", marginTop:"5%"}}>
                <div className = "flex-initial flex justify-center items-center" style={{borderBottom:"1px solid gray"}}>
                  <h1 className="pageContent pageHeading text-4xl py-5 text-center" style={{paddingTop:"10%",paddingBottom:"10%", color:`${headingColor}`}}>WHY NOGGIN?</h1>
                </div>
                <div className="flex-initial" style={{borderBottom:"1px solid gray"}}>
                  <div className="flex justify-center items-center">
                    <h1 className= "text-2xl pageContent text-center" style={{fontFamily:"SF Pro Bold", paddingTop:"10%",color:`${headingColor}`}}>Security Encryption</h1>
                  </div>
                  <p className= "text-md pageContent text-center" style={{fontFamily:"SF Pro", paddingTop:"5%",paddingBottom:"10%"}}>Each POD account is authenticated to the unique end-user. All derived data belongs under the end-user’s choice and control. User has full control over the privacy-settings of the data in her POD account. Each POD account is individually encrypted, and even Noggin has no access unless permitted. All authorized accesses to a POD account is individually controlled by secure cryptographic token.</p>
                </div>
                <div className = "flex-initial" style={{borderBottom:"1px solid gray"}}>
                  <div className="flex justify-center items-center">
                    <h1 className="pageContent text-2xl text-center" style={{fontFamily:"SF Pro Bold", paddingTop:"10%", color:`${headingColor}`}}>Cost Efficient (API Per User)</h1>
                  </div>
                  <p className= "text-md pageContent text-center" style={{fontFamily:"SF Pro",paddingTop:"5%",paddingBottom:"10%"}}>Noggin Client runs on a pay as you use model, allowing your application to scale linearly. Simply transform your application to become PDPA/GDPR Compliance without bill shock. </p>
                </div>
                <div className="flex-initial" style={{borderBottom:"1px solid gray"}}>
                  <div className="flex justify-center items-center">
                    <h1 className= "text-2xl pageContent text-center" style={{fontFamily:"SF Pro Bold", paddingTop:"10%", color:`${headingColor}`}}>PDPA/GDPR Compliance</h1>
                  </div>
                  <p className= "text-md pageContent pb-3 text-center" style={{fontFamily:"SF Pro", paddingTop:"5%",paddingBottom:"10%"}}>The Personal Data Protection Act (PDPA) & The General Data Protection Regulation (GDPR) provides a baseline standard of protection for personal data. It complements sector-specific legislative and regulatory frameworks such as the Banking Act and Insurance Act.</p>
                </div>
            </div>
          </Media>
          <Media query = {{ minWidth: 1520 }}>
          <div className="flex flex-row justify-center items-center" style={{marginTop:"10%", paddingRight:"10%"}}>
            <div className="flex-initial w-4/12">
            <Lottie 
                loop
                play
                animationData={screenAnimation} 
                style={{ width:726, height:550,position:"relative",right:"200px"}}
                />
            </div>
            <div id="why-noggin" className="flex-initial w-8/12 grid grid-rows-2 flex justify-center">
              <div className = "row-span-1 grid grid-cols-12" >
                <div className = "col-span-6" style={{borderTop:"1px solid gray",borderRight:"1px solid gray", borderBottom:"1px solid gray"}}>
                  <h1 className="pageContent pageHeading text-4xl py-5" style={{paddingTop:"16%", paddingLeft:"5%", paddingBottom:"10%",color:`${headingColor}`}}>WHY<br/>NOGGIN?</h1>
                </div>
                <div className="col-span-6" style={{borderTop:"1px solid gray", borderBottom:"1px solid gray"}}>
                  <h1 className= "text-2xl pageContent " style={{fontFamily:"SF Pro Bold", paddingTop:"5%", paddingLeft:"5%", color:`${headingColor}`}}>Security Encryption</h1>
                  <p className= "text-md pageContent" style={{fontFamily:"SF Pro", paddingTop:"2%", paddingLeft:"5%",paddingBottom:"25px"}}>Each POD account is authenticated to the unique end-user. All derived data belongs under the end-user’s choice and control. User has full control over the privacy-settings of the data in her POD account. Each POD account is individually encrypted, and even Noggin has no access unless permitted. All authorized accesses to a POD account is individually controlled by secure cryptographic token.</p>
                </div>
              </div>
              <div className = "row-span-1 grid grid-cols-12">
              <div className = "col-span-7" style={{borderTop:"1px solid gray",borderRight:"1px solid gray", borderBottom:"1px solid gray"}}>
                  <h1 className="pageContent text-2xl" style={{fontFamily:"SF Pro Bold", paddingTop:"25px", paddingLeft:"5%", color:`${headingColor}`}}>Cost Efficient (API Per User)</h1>
                  <p className= "text-md pageContent mb-5" style={{fontFamily:"SF Pro", paddingTop:"2%", paddingLeft:"5%", paddingRight:"5%"}}>Noggin Client runs on a pay as you use model, allowing your application to scale linearly. Simply transform your application to become PDPA/GDPR Compliance without bill shock. </p>
              </div>
                <div className="col-span-5" style={{borderTop:"1px solid gray", borderBottom:"1px solid gray"}}>
                  <h1 className= "text-2xl pageContent" style={{fontFamily:"SF Pro Bold", paddingTop:"25px", paddingLeft:"5%", color:`${headingColor}`}}>PDPA/GDPR Compliance</h1>
                  <p className= "text-md pageContent pb-3" style={{fontFamily:"SF Pro", paddingTop:"2%", paddingLeft:"5%"}}>The Personal Data Protection Act (PDPA) & The General Data Protection Regulation (GDPR) provides a baseline standard of protection for personal data. It complements sector-specific legislative and regulatory frameworks such as the Banking Act and Insurance Act.</p>
                </div>
              </div>
            </div>
          </div>
          </Media>
          

          <div id="who-we-help"  className="flex flex-col lg:flex-row" style={{marginTop:"8%", paddingLeft:"10%",paddingRight:"10%"}}>
            <div className="flex-initial w-full lg:w-4/12">
              <h1 className={"pageContent pageHeading text-center lg:text-left " + headingFont} style={{color:`${headingColor}`}}>WHO WE'VE HELPED?</h1>
              <p style={{fontFamily:"SF Pro Bold",paddingTop:"3%", paddingRight:"5%",color:`${headingColor}`}} className="text-lg text-center lg:text-left ">
              Some of our clients in alphabetical order
              </p>
            </div>
            <div className="flex-intial" style={{height:"30px", marginLeft: "100px"}}></div>
            <Media query = {{minWidth:723}}>
            <div className="flex-intial w-full lg:w-7/12 flex justify-center items-center grid grid-rows" style={{height:500,float:"right"}}>
              <div className="row-span-3 grid grid-cols-11">
                <div className="col-span-3 rounded-lg shadow-lg item-center flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <img src={aegisLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                </div>
                <div className="col-span-3 col-start-5 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <img src={barworksLogo} style={{margin:"auto", maxWidth:"80%", maxHeight: "80%"}}></img>
                </div>
                <div className="col-span-3 col-start-9 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                <img src={ihhLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                </div>
              </div>
              <div className="row-span-3 grid grid-cols-11">
                <div className="col-span-3 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <img src={kimLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                </div>
                <div className="col-span-3 col-start-5 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <img src={nanyangLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                </div>
                <div className="col-span-3 col-start-9 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <img src={scsLogo} style={{margin:"auto", maxWidth:"80%", maxHeight: "100%"}}></img>
                </div>
              </div>
              <div className="row-span-3 grid grid-cols-11">
              {/* <div className="col-span-3 col-start-5 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <img src={ourLogo} style={{margin:"auto", maxWidth:"80%", maxHeight: "100%"}}></img>
                </div>
                <div className="col-span-3 col-start-9 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <img src={seedVenturesLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                </div> */}
                <div className="col-span-3 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <img src={simLogo} style={{margin:"auto", maxWidth:"80%", maxHeight: "100%"}}></img>
                </div>
                <div className="col-span-3 col-start-5 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <img src={starhubLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                </div>
                {/* <div className="col-span-3 col-start-9 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <p className="font-semibold text-center" style={{fontFamily:"DM Sans Bold", margin:"auto", maxWidth:"80%"}}>And Many More...</p>
                </div> */}
                <div className="col-span-3 col-start-9 flex justify-center"  style={{ marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <p className={"font-semibold text-left text-lg " + (theme === "dark" ? "text-white" : "text-[#584A89]")} style={{fontFamily:"SF Pro Bold", margin:"auto", maxWidth:"80%", marginRight:"50px"}}>And Many More...</p>                </div>
                </div>
              {/* <Media query = {{minWidth:1024}}>
              <div className="row-span-3 grid grid-cols-11">
                <div className="col-span-3 col-start-9 rounded-lg shadow-lg" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <p className="font-semibold text-center" style={{fontFamily:"DM Sans Bold", margin:"auto", top:65, position:"relative", maxWidth:"80%"}}>And Many More...</p>
                </div>
              </div>
              </Media> */}
            </div>
            </Media>
            <Media query = {{maxWidth:722}}>
              <div>
                <div className="flex flex-row">
                  <div className="w-5/12 flex flex-col flex-initial">
                    <div className="flex-initial rounded-lg shadow-lg item-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                      <img src={aegisLogo} style={{margin:"auto", maxWidth:"80%", maxHeight: "70%"}}></img>
                    </div>
                    <div className="flex-initial rounded-lg shadow-lg items-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                      <img src={ihhLogo} style={{margin:"auto",maxWidth:"80%", maxWidth:"80%", maxHeight: "80%"}}></img>
                    </div>
                    <div className="flex-initial rounded-lg shadow-lg item-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                      <img src={nanyangLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                    </div>
                    <div className="flex-initial rounded-lg shadow-lg item-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                      <img src={scsLogo} style={{margin:"auto", maxWidth:"80%", maxHeight: "100%"}}></img>
                    </div>
                    
                  </div>
                  <div className="w-2/12"></div>
                  <div className="w-5/12 flex flex-col flex-initial">
                    <div className="flex-initial rounded-lg shadow-lg item-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                    <img src={barworksLogo} style={{margin:"auto", maxWidth:"80%", maxHeight:"80%",height:"80%"}}></img>
                    </div>
                    <div className="flex-initial rounded-lg shadow-lg item-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                    <img src={kimLogo} style={{margin:"auto", maxWidth:"80%", maxHeight: "80%"}}></img>
                    </div>
                    <div className="flex-initial rounded-lg shadow-lg items-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                    <img src={simLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                    </div>
                    <div className="flex-initial rounded-lg shadow-lg items-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                    <img src={starhubLogo} style={{margin:"auto", maxWidth:"80%", maxHeight: "80%"}}></img>
                    </div>
                    {/* <div className="flex-initial rounded-lg shadow-lg item-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                      <p className="font-semibold text-center" style={{fontFamily:"DM Sans Bold", margin:"auto", maxWidth:"80%"}}>And Many More...</p>
                    </div> */}
                  </div>
                </div>
                <div className="flex-initial item-center justify-center mb-4 flex mx-auto" style={{maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                      <p className={"font-semibold text-center text-lg " + (theme === "dark" ? "text-white" : "text-[#584A89]")} style={{fontFamily:"SF Pro Bold", maxWidth:"80%"}}>And Many More...</p>
                    </div>
                {/* <div className="w-5/12 flex flex-col flex-initial">
                    <div className="flex-initial rounded-lg shadow-lg items-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                    <img src={starhubLogo} style={{margin:"auto", maxWidth:"80%", maxHeight: "80%"}}></img>
                    </div>
                    </div> */}
                
              </div>
            </Media>
          </div>

          <div id="who-we-work-with" className="flex flex-col lg:flex-row" style={{marginTop:"8%", paddingLeft:"10%",paddingRight:"10%"}}>
            <div className="flex-initial w-full lg:w-5/12">
              <h1 className={"pageContent pageHeading text-center lg:text-left " + headingFont} style={{color:`${headingColor}`}}>WHO WE WORK WITH?</h1>
              <p style={{fontFamily:"SF Pro Bold",paddingTop:"3%", paddingRight:"5%",color:`${headingColor}`}} className="text-lg text-center lg:text-left">
              Some of our partners in alphabetical order.
              </p>
            </div>
            <div className="flex-intial" style={{height:"30px"}}></div>
            <Media query = {{minWidth:723}}>
            <div className="flex-intial w-full lg:w-7/12 flex justify-center items-center grid grid-rows" style={{height:500,float:"right"}}>
              <div className="row-span-3 grid grid-cols-11">
                <div className="col-span-3 rounded-lg shadow-lg item-center flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <img src={amazonLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                </div>
                <div className="col-span-3 col-start-5 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <img src={fortiedgeLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                </div>
                <div className="col-span-3 col-start-9 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                <img src={iminicodeLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                </div>
              </div>
              
                

                <Media query = {{ maxWidth:1023 }}>
                <div className="row-span-3 grid grid-cols-11">
                <div className="col-span-3 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                    <img src={nucleoLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                  </div>
                  <div className="col-span-3 col-start-5 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                    <img src={seedVenturesLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                  </div>
                  <div className="col-span-3 col-start-9 flex justify-center" style={{ marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <p className={"font-semibold text-left text-lg "+ (theme === "dark" ? "text-white" : "text-[#584A89]")} style={{fontFamily:"SF Pro Bold", margin:"auto", maxWidth:"80%", marginRight:"50px"}}>And Many More...</p>
                  </div>
                  </div>
                </Media>
              
              <Media query = {{minWidth:1024}}>
              <div className="row-span-3 grid grid-cols-11">
              <div className="col-span-3 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <img src={nucleoLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                </div>
                <div className="col-span-3 col-start-5 rounded-lg shadow-lg flex justify-center" style={{backgroundColor:"#f5f5f5", marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                <img src={seedVenturesLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                </div>
                <div className="col-span-3 col-start-9 flex justify-center" style={{marginBottom:"10%", maxHeight:120, maxWidth:250, minHeight:150}}>
                  <p className={"font-semibold text-left text-lg " + (theme === "dark" ? "text-white" : "text-[#584A89]")} style={{fontFamily:"SF Pro Bold", margin:"auto", maxWidth:"80%", marginRight:"50px"}}>And Many More...</p>
                </div>
                <div className="col-span-3 col-start-9 flex justify-center" style={{ marginBottom:"10%", maxHeight:"120px", maxWidth:250, minHeight:150}}>
                {/* <p className={"font-semibold text-left text-2xl " + (theme === "dark" ? "text-white" : "text-[#584A89]")} style={{fontFamily:"SF Pro Bold", margin:"auto", maxWidth:"80%", marginRight:"50px"}}>And Many More...</p> */}
                </div>
              </div>
              </Media>
            </div>
            </Media>
            <Media query = {{maxWidth:722}}>
              <div className="flex flex-row">
                <div className="w-5/12 flex flex-col flex-initial">
                  <div className="flex-initial rounded-lg shadow-lg item-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                    <img src={amazonLogo} style={{margin:"auto",  maxWidth:"80%", maxHeight: "80%"}}></img>
                  </div>
                  <div className="flex-initial rounded-lg shadow-lg items-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                    <img src={fortiedgeLogo} style={{margin:"auto",maxWidth:"80%",maxHeight:"80%",height:"80%"}}></img>
                  </div>
                  <div className="flex-initial rounded-lg shadow-lg item-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                    <img src={iminicodeLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                  </div>
                </div>
                <div className="w-2/12"></div>
                <div className="w-5/12 flex flex-col flex-initial">
                  
                  <div className="flex-initial rounded-lg shadow-lg item-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                  <img src={nucleoLogo} style={{margin:"auto", maxWidth:"80%"}}></img>
                  </div>
                  <div className="flex-initial rounded-lg shadow-lg items-center justify-center mb-4 flex" style={{backgroundColor:"#f5f5f5",maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                  <img src={seedVenturesLogo} style={{margin:"auto", maxWidth:"80%", maxHeight: "80%"}}></img>
                  </div>
                  <div className="flex-initial item-center justify-center mb-4 flex" style={{maxHeight:100, maxWidth:250, minHeight:100,padding:"5%"}}>
                  <p className={"font-semibold text-center text-lg " + (theme === "dark" ? "text-white" : "text-[#584A89]")} style={{fontFamily:"SF Pro Bold", margin:"auto", maxWidth:"80%"}}>And Many More...</p>
                  </div>
                </div>
              </div>
            </Media>
          </div>

          
          <div style={{height:"100px"}}></div>
          <Quote/>
          <Footer/>
      </AnimatedPage>
      </>
    );
  }
  
export default Home;
  