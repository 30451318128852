import React, { useContext, useEffect, useState, useRef } from 'react';
import {Link, NavLink} from 'react-router-dom';
import { ThemeContext } from '../ThemeContext/ThemeContext';
import Media from 'react-media'
import { transparent } from 'tailwindcss/colors';
import { useLocation } from 'react-router-dom';


var navItemDark = "text-stone-200"
var navItemLight = "text-md"
const nogginLogo = require("../../img/NogginLogo3.png")
const nogginWhiteLogo = require("../../img/Asset 5.png")

const Navbar = (currentPage) => {
    const location = useLocation();
    console.log("page,", location.pathname)
    if (location.pathname === '/how-noggin-works') {
        navItemDark = "text-stone-200"
        navItemLight = "text-stone-200"
    }
    else {
        navItemDark = "text-stone-200"
        navItemLight = "text-md"
    }
    const {theme, setTheme} = useContext(ThemeContext)

    const [check, setCheck] = useState(false);
    const [themeCheck, setThemeCheck] = useState(false);

    const [navColor, setNavColor] = useState(false)
    const changeNavColor = () => {
        if (window.scrollY >= 50) {
            setNavColor(true)
        }
        else {
            setNavColor(false)
        }
    }
    console.log("color",navColor)
    window.addEventListener('scroll', changeNavColor)

    const today = new Date()

    React.useEffect(() => {
        console.log(today.getHours())
        if(today.getHours() > 19 || today.getHours() < 8){
            setTheme("dark")
            setThemeCheck(true)
          }
    },[])


    return (
        <div className={`top-0 flex flex-row navBar w-full py-3 ` + (!navColor ?  transparent : location.pathname === '/how-noggin-works' ? "navBar-bgDark" : (theme === "dark" ? "navBar-bgDark" : "navBar-bgLight"))} style={{borderStyle:"solid",position:"fixed",transition:"background-color 500ms linear",paddingLeft:"10%",paddingRight:"6%"}}>
            <div className="flex-none flex flex-row items-start">
                <div className = {"flex-1 flex flex-row " + (theme === "dark" ? navItemDark : navItemLight)} >
                    <div className='flex-initial' style= {{ minWidth:"148px",maxWidth:"148px"}}>
                    {
                        location.pathname === '/how-noggin-works' ? <NavLink to="/"><img src = {nogginWhiteLogo} style={{width:"60%", height:"80%"}} className="mt-1" onClick={() => setCheck(false)}></img></NavLink> : (theme === "light" ? <NavLink to="/"><img src = {nogginLogo} style={{width:"60%", height:"80%"}} className="mt-1" onClick={() => setCheck(false)}></img></NavLink>
                                    : <NavLink to="/"><img src = {nogginWhiteLogo} style={{width:"60%", height:"80%"}} className="mt-1" onClick={() => setCheck(false)}></img></NavLink>)
                    }
                    </div>
                </div>
            </div>
            <div className='flex-initial' style={{width:"80%"}}></div>
            <Media query={{ minWidth: 1024 }}>
                <nav className='flex flex-row'>
                <div className={"flex-1 w-28 " + (theme === "dark" ? navItemDark : navItemLight)} style={{marginTop:"10px"}}>
                        <NavLink to="/" style={{fontFamily:"SF Pro Bold"}} className="ml-5">Home</NavLink>
                </div>
                <div className={"dropdown flex-1 w-52"}>
                    <button className={"dropbtn " + (theme === "dark" ? navItemDark : navItemLight)}><p style={{fontFamily:"SF Pro Bold",lineHeight: "1.6", marginTop:"9px"}}>Services</p>
                    </button>
                    <div class="dropdown-content">
                        <div className="w-full rounded-lg">
                            <NavLink className={"navDropDown " + (theme === "dark" ? navItemDark : navItemLight)} to="/business">
                                <p style={{fontFamily:"SF Pro Bold", fontSize:"14px"}} className={"flex-row flex"}>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" style={{color:"#8b4513"}} viewBox="0 0 20 20" fill="currentColor" >
                                    <path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" />
                                    <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
                                    </svg>
                                    For Business 
                                </p>
                            </NavLink>
                            <NavLink className={"navDropDown " + (theme === "dark" ? navItemDark : navItemLight)} to="/developers">
                                <p style={{fontFamily:"SF Pro Bold", border:"none", fontSize:"14px"}}  className={"flex-row flex"}>
                                <svg xmlns="http://www.w3.org/2000/svg" style={{color:"#00008B"}} className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={3}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                                    </svg>
                                    For Developers
                                </p>
                                </NavLink>
                            <NavLink className={"navDropDown " + (theme ==="dark" ? navItemDark : navItemLight)} to="/users" style={{pointerEvents:"none", backgroundColor:"#C1C1C1"}}>
                                <p style={{fontFamily:"SF Pro Bold", border:"none", fontSize:"14px"}} className={"flex-row flex"}>                                
                                <svg xmlns="http://www.w3.org/2000/svg" style={{color:"#695a9c"}} class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="3">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                </svg>For Users (Coming Soon!)
                                </p>
                                </NavLink>
                        </div>
                    </div>
                </div>
                    <div className={"flex-1 w-28 mt-[9px] " + (theme === "dark" ? navItemDark : navItemLight)}>
                        <NavLink to="/about-us" style={{fontFamily:"SF Pro Bold"}}>About Us</NavLink>
                    </div>
                    <div className={"flex-1 w-28 mt-[9px] " + (theme === "dark" ? navItemDark : navItemLight)}>
                    <NavLink to="/contact-us" style={{fontFamily:"SF Pro Bold"}}>Contact Us</NavLink>
                    </div>
                    <div className="flex-1" style={{marginLeft:"28px", marginTop:"8px"}}>
                    <input type="checkbox" class="checkbox" id="checkbox" checked={themeCheck} onClick={() => {
                       if(theme =="dark"){
                        setTheme("light")
                        setThemeCheck(false)
                    }else{
                        setTheme("dark")
                        setThemeCheck(true)
                    }
                    }}/>
                    <label for="checkbox" class="label">
                    <div className = {"ball "} style={{backgroundColor:"#4f4d4d", height:"15px", width: "15px"}}/>
                    </label>
                </div>
                </nav>
            </Media>
            <Media query={{ maxWidth : 1023}}>
                <Media query={{ minHeight : 591}}>
                <>
                {!check &&
                <div className='mt-[12px] block lg:hidden' onClick={() => setCheck((c) => !c)}>
                        {  location.pathname === '/how-noggin-works' ?  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
                                        <g id="Group_1735" data-name="Group 1735" transform="translate(-315 -7)">
                                            <rect id="Rectangle_118" data-name="Rectangle 118" width="20" height="3" rx="1.5" transform="translate(315 7)" fill="#f5f5f5"/>
                                            <rect id="Rectangle_119" data-name="Rectangle 119" width="15" height="3" rx="1.5" transform="translate(320 13)" fill="#f5f5f5"/>
                                            <rect id="Rectangle_120" data-name="Rectangle 120" width="20" height="3" rx="1.5" transform="translate(315 19)" fill="#f5f5f5"/>
                                        </g>
                                    </svg> : 
                            (theme === "light" ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
                                                    <g id="Group_1735" data-name="Group 1735" transform="translate(-315 -7)">
                                                    <rect id="Rectangle_118" data-name="Rectangle 118" width="20" height="3" rx="1.5" transform="translate(315 7)" fill="#000000"/>
                                                    <rect id="Rectangle_119" data-name="Rectangle 119" width="15" height="3" rx="1.5" transform="translate(320 13)" fill="#000000"/>
                                                    <rect id="Rectangle_120" data-name="Rectangle 120" width="20" height="3" rx="1.5" transform="translate(315 19)" fill="#000000"/>{/*584a89*/}
                                                    </g>
                                                </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
                                        <g id="Group_1735" data-name="Group 1735" transform="translate(-315 -7)">
                                            <rect id="Rectangle_118" data-name="Rectangle 118" width="20" height="3" rx="1.5" transform="translate(315 7)" fill="#f5f5f5"/>
                                            <rect id="Rectangle_119" data-name="Rectangle 119" width="15" height="3" rx="1.5" transform="translate(320 13)" fill="#f5f5f5"/>
                                            <rect id="Rectangle_120" data-name="Rectangle 120" width="20" height="3" rx="1.5" transform="translate(315 19)" fill="#f5f5f5"/>
                                        </g>
                                    </svg>
                            )
                        }
                    </div>
                }

                {/* mobile navbar */}
                {check && <div className={'fixed right-0 top-0 w-[65%] h-full ' + (theme === "dark" ? "bg-[#171717]" : "bg-[#E7EBF1]")}>
                    <div className='mt-[20px] float-right mr-[20px]' onClick={() => setCheck((c) => !c)}>
                        {
                            theme === "light" ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <g id="Group_1732" data-name="Group 1732" transform="translate(10465.071 12105.071)">
                                                    <path id="Union_1" data-name="Union 1" d="M16.508,19.4,10,12.892,3.492,19.4A2.045,2.045,0,0,1,.6,16.508L7.107,10,.6,3.492A2.045,2.045,0,0,1,3.492.6L10,7.107,16.508.6A2.045,2.045,0,0,1,19.4,3.492L12.892,10,19.4,16.509A2.045,2.045,0,1,1,16.508,19.4Z" transform="translate(-10465.071 -12105.071)" fill="#000000"/>
                                                </g>
                                            </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <g id="Group_1733" data-name="Group 1733" transform="translate(10465.071 12105.071)">
                                            <path id="Union_1" data-name="Union 1" d="M16.508,19.4,10,12.892,3.492,19.4A2.045,2.045,0,0,1,.6,16.508L7.107,10,.6,3.492A2.045,2.045,0,0,1,3.492.6L10,7.107,16.508.6A2.045,2.045,0,0,1,19.4,3.492L12.892,10,19.4,16.509A2.045,2.045,0,1,1,16.508,19.4Z" transform="translate(-10465.071 -12105.071)" fill="#f5f5f5"/>
                                        </g>
                                    </svg>
                        }
                    </div>
                    <div style={{height:"100px"}}></div>
                    <ul>
                    <li className="uppercase p-4 cursor-pointer text-right">
                        <NavLink to="/" className={" "+ (theme === "dark" ? "text-[#F5F5F5]" : "text-[#282828]")} style={{fontFamily:"SF Pro Bold"}} onClick={() => setCheck((c) => !c)}>Home</NavLink>
                    </li>
                    <li className="uppercase p-4 cursor-pointer text-right">
                        <NavLink to="/business" className={" "+ (theme === "dark" ? "text-[#F5F5F5]" : "text-[#282828]")} style={{fontFamily:"SF Pro Bold"}} onClick={() => setCheck((c) => !c)}>Noggin For Business</NavLink>
                    </li>
                    <li className="uppercase p-4 cursor-pointer text-right">
                        <NavLink to="/developers" className={" "+ (theme === "dark" ? "text-[#F5F5F5]" : "text-[#282828]")} style={{fontFamily:"SF Pro Bold"}} onClick={() => setCheck((c) => !c)}>Noggin For Developers</NavLink>
                    </li>
                    <li className="uppercase p-4 cursor-none text-right">
                        <p to="#" className={" "+ (theme === "dark" ? "text-[#393939]" : "text-[#C1C1C1]")} style={{fontFamily:"SF Pro Bold"}}>Noggin For Users</p>
                        {/* <NavLink to="#" className={" "+ (theme === "dark" ? "text-[#393939]" : "text-[#C1C1C1]")} style={{fontFamily:"SF Pro Bold"}} onClick={() => setCheck((c) => !c)}>Noggin For Users</NavLink> */}
                    </li>
                    <li className="uppercase p-4 cursor-pointer text-right">
                        <NavLink to="/about-us" className={" "+ (theme === "dark" ? "text-[#F5F5F5]" : "text-[#282828]")} style={{fontFamily:"SF Pro Bold"}} onClick={() => setCheck((c) => !c)}>About Us</NavLink>
                    </li>
                    <li className="uppercase p-4 cursor-pointer text-right">
                        <NavLink to="/contact-us" className={" "+ (theme === "dark" ? "text-[#F5F5F5]" : "text-[#282828]")} style={{fontFamily:"SF Pro Bold"}} onClick={() => setCheck((c) => !c)}>Contact Us</NavLink>
                    </li>
                    <li> 
                        <div className="flex-initial p-4 float-right">
                            <input type="checkbox" class="checkbox" id="checkbox" checked={themeCheck} onClick={() => {
                            if(theme =="dark"){
                                setTheme("light")
                                setThemeCheck(false)
                            }else{
                                setTheme("dark")
                                setThemeCheck(true)
                            }
                            }}/>
                            <label for="checkbox" class="label">
                            <div className = {"ball "} style={{backgroundColor:"#4f4d4d", height:"15px", width: "15px"}}/>
                            </label>
                        </div>
                    </li>
                    </ul>
                </div>}
                </>
                </Media>
            </Media>
            <Media query={{ maxWidth : 1023}}>
                <Media query={{ maxHeight : 590}}>
                <>
                {!check &&
                <div className='mt-[12px] block lg:hidden' onClick={() => setCheck((c) => !c)}>
                        {  location.pathname === '/how-noggin-works' ?  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
                                        <g id="Group_1735" data-name="Group 1735" transform="translate(-315 -7)">
                                            <rect id="Rectangle_118" data-name="Rectangle 118" width="20" height="3" rx="1.5" transform="translate(315 7)" fill="#f5f5f5"/>
                                            <rect id="Rectangle_119" data-name="Rectangle 119" width="15" height="3" rx="1.5" transform="translate(320 13)" fill="#f5f5f5"/>
                                            <rect id="Rectangle_120" data-name="Rectangle 120" width="20" height="3" rx="1.5" transform="translate(315 19)" fill="#f5f5f5"/>
                                        </g>
                                    </svg> : 
                            (theme === "light" ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
                                                    <g id="Group_1735" data-name="Group 1735" transform="translate(-315 -7)">
                                                    <rect id="Rectangle_118" data-name="Rectangle 118" width="20" height="3" rx="1.5" transform="translate(315 7)" fill="#000000"/>
                                                    <rect id="Rectangle_119" data-name="Rectangle 119" width="15" height="3" rx="1.5" transform="translate(320 13)" fill="#000000"/>
                                                    <rect id="Rectangle_120" data-name="Rectangle 120" width="20" height="3" rx="1.5" transform="translate(315 19)" fill="#000000"/>{/*584a89*/}
                                                    </g>
                                                </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
                                        <g id="Group_1735" data-name="Group 1735" transform="translate(-315 -7)">
                                            <rect id="Rectangle_118" data-name="Rectangle 118" width="20" height="3" rx="1.5" transform="translate(315 7)" fill="#f5f5f5"/>
                                            <rect id="Rectangle_119" data-name="Rectangle 119" width="15" height="3" rx="1.5" transform="translate(320 13)" fill="#f5f5f5"/>
                                            <rect id="Rectangle_120" data-name="Rectangle 120" width="20" height="3" rx="1.5" transform="translate(315 19)" fill="#f5f5f5"/>
                                        </g>
                                    </svg>
                            )
                        }
                    </div>
                }

                {/* mobile navbar */}
                {check && <div className={'fixed right-0 top-0 w-[65%] h-full ' + (theme === "dark" ? "bg-[#171717]" : "bg-[#E7EBF1]")}>
                    <div className='mt-[20px] float-right mr-[20px]' onClick={() => setCheck((c) => !c)}>
                        {
                            theme === "light" ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <g id="Group_1732" data-name="Group 1732" transform="translate(10465.071 12105.071)">
                                                    <path id="Union_1" data-name="Union 1" d="M16.508,19.4,10,12.892,3.492,19.4A2.045,2.045,0,0,1,.6,16.508L7.107,10,.6,3.492A2.045,2.045,0,0,1,3.492.6L10,7.107,16.508.6A2.045,2.045,0,0,1,19.4,3.492L12.892,10,19.4,16.509A2.045,2.045,0,1,1,16.508,19.4Z" transform="translate(-10465.071 -12105.071)" fill="#000000"/>
                                                </g>
                                            </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <g id="Group_1733" data-name="Group 1733" transform="translate(10465.071 12105.071)">
                                            <path id="Union_1" data-name="Union 1" d="M16.508,19.4,10,12.892,3.492,19.4A2.045,2.045,0,0,1,.6,16.508L7.107,10,.6,3.492A2.045,2.045,0,0,1,3.492.6L10,7.107,16.508.6A2.045,2.045,0,0,1,19.4,3.492L12.892,10,19.4,16.509A2.045,2.045,0,1,1,16.508,19.4Z" transform="translate(-10465.071 -12105.071)" fill="#f5f5f5"/>
                                        </g>
                                    </svg>
                        }
                    </div>
                    <div style={{height:"55px"}}></div>
                    <ul>
                    <li className="uppercase px-4 py-[3px] cursor-pointer text-right">
                        <NavLink to="/" className={" "+ (theme === "dark" ? "text-[#F5F5F5]" : "text-[#282828]")} style={{fontFamily:"SF Pro Bold"}} onClick={() => setCheck((c) => !c)}>Home</NavLink>
                    </li>
                    <li className="uppercase px-4 py-[3px] cursor-pointer text-right">
                        <NavLink to="/business" className={" "+ (theme === "dark" ? "text-[#F5F5F5]" : "text-[#282828]")} style={{fontFamily:"SF Pro Bold"}} onClick={() => setCheck((c) => !c)}>Noggin For Business</NavLink>
                    </li>
                    <li className="uppercase px-4 py-[3px] cursor-pointer text-right">
                        <NavLink to="/developers" className={" "+ (theme === "dark" ? "text-[#F5F5F5]" : "text-[#282828]")} style={{fontFamily:"SF Pro Bold"}} onClick={() => setCheck((c) => !c)}>Noggin For Developers</NavLink>
                    </li>
                    <li className="uppercase px-4 py-[3px] cursor-none text-right">
                        <p to="#" className={" "+ (theme === "dark" ? "text-[#393939]" : "text-[#C1C1C1]")} style={{fontFamily:"SF Pro Bold"}}>Noggin For Users</p>
                        {/* <NavLink to="#" className={" "+ (theme === "dark" ? "text-[#393939]" : "text-[#C1C1C1]")} style={{fontFamily:"SF Pro Bold"}} onClick={() => setCheck((c) => !c)}>Noggin For Users</NavLink> */}
                    </li>
                    <li className="uppercase px-4 py-[3px] cursor-pointer text-right">
                        <NavLink to="/about-us" className={" "+ (theme === "dark" ? "text-[#F5F5F5]" : "text-[#282828]")} style={{fontFamily:"SF Pro Bold"}} onClick={() => setCheck((c) => !c)}>About Us</NavLink>
                    </li>
                    <li className="uppercase px-4 py-[3px] cursor-pointer text-right">
                        <NavLink to="/contact-us" className={" "+ (theme === "dark" ? "text-[#F5F5F5]" : "text-[#282828]")} style={{fontFamily:"SF Pro Bold"}} onClick={() => setCheck((c) => !c)}>Contact Us</NavLink>
                    </li>
                    <li> 
                        <div className="flex-initial px-4 py-[3px] float-right">
                            <input type="checkbox" class="checkbox" id="checkbox" checked={themeCheck} onClick={() => {
                            if(theme =="dark"){
                                setTheme("light")
                                setThemeCheck(false)
                            }else{
                                setTheme("dark")
                                setThemeCheck(true)
                            }
                            }}/>
                            <label for="checkbox" class="label">
                            <div className = {"ball "} style={{backgroundColor:"#4f4d4d", height:"15px", width: "15px"}}/>
                            </label>
                        </div>
                    </li>
                    </ul>
                </div>}
                </>
                </Media>
            </Media>
        </div>
    )
}
export default Navbar;