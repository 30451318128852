import AnimatedPage from "../AnimatedPage/AnimatedPage";
import React, { useContext, useRef, useEffect, useState } from "react";
import { ThemeContext } from "../../modules/ThemeContext/ThemeContext";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import Media from "react-media";
import Footer from "../../modules/footer";
import { Helmet } from "react-helmet";
import Quote from "../../modules/quote";

const bznessAnimation = require('../../lottieJsons/67986-business-deal.json')
const podsAnimation = require('../../lottieJsons/lf30_editor_qi6idhcl.json')
const speakerIcon = require('../../img/speakerIcon.png')
const customisedEnterpriseIcon = require('../../img/customised-enterprise.png')
const systemIntegrationIcon = require('../../img/system.png')
const webDevelopmentIcon = require('../../img/software.png')
const mobileDevelopmentIcon = require('../../img/mobile-development.png')
const servicesIcon = require('../../img/target.png')

const Business = () => {
  const {theme, setTheme} = useContext(ThemeContext)
  const fontColor = (theme === "dark" ? "rgb(231 229 228)" : "black")
  const headingColor = (theme === "dark" ? "#E7EBF1" : "#584a89")
  const headingFont = " text-2xl sssm:text-3xl sm:text-4xl md:text-5xl "

    return (
      <>
      <Helmet>
        <title>Noggin – For Businesses</title>
        <meta
          name="description"
          content="Share data insights without sharing business/personal data. Here, at Noggin, we offer various services ranging from customized enterprise solutions to consulting services."
        />
        <meta
            name="keywords"
            content="Noggin, Noggin for Business, Customized Enterprise Solutions, Systems Integration, Mobile Development, Consulting Services"
        />
      </Helmet>
        <div className ={"2xl:snap-mandatory 2xl:snap-y overflow-scroll maindiv "+ (theme === "dark" ? "businessBg-dark" : "businessBg-light")}>
        <div className="h-screen flex flex-col lg:flex-row snap-center " style={{paddingLeft:"10%",paddingRight:"10%"}}>
            <div className="flex-initial flex flex-col lg:w-1/2 lg:mt-[200px] xl:mt-[250px] mt-[55px]">
              <h1 className={"pageContent pageHeading text-center lg:text-left " + headingFont} style={{paddingTop:"15%", color:`${headingColor}`}}>NOGGIN
               <br/>
               <span className={"pageContent pageHeading text-center lg:text-left " + headingFont} style={{color:`${headingColor}`}}>FOR <span className="underlined" style={{fontFamily:"Neutro"}}>BUSINESS</span></span>
               </h1>
              <div className = "pageContent text-center lg:text-left mt-4 ">
              <p style={{fontFamily:"SF Pro", color:`${fontColor}`}}>Companies exchange inferred data outputted by analytics and algorithms from 
              <br/>
              <span style={{fontFamily:"SF Pro", color:`${fontColor}`}}>the “raw” data collected, while preventing access to the original “raw” data. </span>
              </p>
              </div>
            </div>
            <Media query= {{minWidth:1024}}>
              <div className="flex-initial w-full lg:w-1/2 flex justify-center">
              <Lottie 
                  loop
                  animationData={bznessAnimation} 
                  play
                  style={{ width:476, height:517, position:"relative"}}
                  className="xl:mt-[150px] lg:mt-[100px]"
                  />
              </div>
            </Media>
            <Media query= {{maxWidth:1023}}>
              <Media query= {{maxHeight:590}}>
              <div className="flex-initial w-full lg:w-1/2 flex justify-center">
              <Lottie 
                  loop
                  animationData={bznessAnimation} 
                  play
                  style={{ width:476, height:517, position:"relative"}}
                  className="hidden"
                  />
              </div>
            </Media>
            </Media>
            <Media query= {{maxWidth:1023}}>
              <Media query= {{minHeight:591}}>
              <div className="flex-initial ssssm:w-[80%] md:w-[60%] mmmd:w-[50%] mx-auto justify-center items-center">
              <Lottie 
                  loop 
                  animationData={bznessAnimation} 
                  play
                  style={{ position:"relative"}}
                  className="mt-4"
                  />
              </div>
            </Media>
            </Media>

          </div>
          
          <div style={{height:150}}></div>

          

           <div style={{paddingLeft:"10%",paddingRight:"10%"}}>
           <Media query= {{minWidth:768}}>
          <div className="flex flex-col justify-center items-center">
                <h1 style={{color:`${headingColor}`,fontFamily:"Neutro"}} className="text-4xl text-center">Noggin</h1>
                
                <h1 style={{color:`${headingColor}`,fontFamily:"Neutro"}} className="text-4xl text-center">Services & Products</h1>
              </div>
            </Media>
            <Media query= {{maxWidth:767}}>
            <div className="flex flex-col justify-center items-center">
                <h1 style={{color:`${headingColor}`,fontFamily:"Neutro"}} className="text-4xl mb-2">Noggin</h1>
                
                <h1 style={{color:`${headingColor}`,fontFamily:"Neutro"}} className="text-4xl text-center mb-3">Services<br/>&<br/>Products</h1>
              </div>
            </Media>
           <div className="serviceDiv p-10 snap-center 2xl:py-0" style={{minHeight:"330px" ,marginTop:"1%",width:"100%"}}>
            <div className="flex flex-col-reverse 2xl:flex-row">
                  <div className="flex flex-col 2xl:flex-row w-full justify-center 2xl:justify-start 2xl:w-7/12 2xl:ml-10">


                  <Media query = {{maxWidth:1535}}>
                    <>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item border border-gray-200 bg-white mt-1" style={{borderRadius:"15px"}}>
                        <h2 class="accordion-header mb-0" id="headingEight" >
                          <button class="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none" style={{borderRadius:"15px"}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                            <p style={{fontFamily:"SF Pro Bold", color:"#584a89"}}>SMART DATA EXCHANGE</p>
                          </button>
                        </h2>
                        <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight"
                          data-bs-parent="#accordionExample">
                          <div class="accordion-body py-4 px-5  ">
                            <p style={{fontFamily:"SF Pro", color:"#584a89",lineHeight:"22px"}} className="text-md">Noggin.ai uses machine-learning algorithms to produce “smart data”. You must first obtain the user data and permit its safe exchange.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                    </div>
                    </>
                  </Media>
                <Media query = {{minWidth:1536}}>
                <>
                <div className="w-full 2xl:w-4/12 flex justify-center 2xl:justify-start">
                    <div style={{maxWidth:"220px",width:"215px",minWidth:"200px",minHeight:"270px"}} className="flex flex-col justify-center servicesDiv">
                      <h1 style={{fontFamily:"SF Pro Bold",wordBreak:"break-word", color:"#FFFFFF",lineHeight:"22px"}} className="text-xl">SMART DATA<br/>EXCHANGE
                      </h1>
                      <br />
                      <p style={{fontFamily:"SF Pro", color:"#F2F2F2",lineHeight:"22px"}} className="text-md">Noggin.ai uses machine-learning algorithms to produce “smart data”. You must first obtain the user data and permit its safe exchange.</p>
                    </div>
                    </div>  
                </>
                </Media>
                </div>
                <div className="flex flex-col w-full justify-center 2xl:w-5/12 2xl:ml-8 mb-3">
                  <div style={{paddingRight:"5%"}}>
                  <div className="flex flex-row w-full justify-start 2xl:justify-start" style={{marginTop:"5%"}}>
                    <img alt="" className="rounded-xl" style={{ maxHeight:70,maxWidth:65, height:'100%',width:"100%"}}src={speakerIcon}></img>
                    <div style={{width:"2%"}}></div>
                    <h1 style={{fontFamily:"SF Pro Bold", color:"#f5f5f5"}} className="text-2xl ml-3">Noggin Platform <br />
                      <span>Services</span>
                    </h1>
                  </div>
                  <br />
                  <div>
                    <p style={{fontFamily:"SF Pro", color:"#f5f5f5"}}>Noggin.ai offers the innovative solution of collaborative data pooling. This form of data exchange is more secure and private, while retaining the benefits of smart data.
                    </p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">

              <div className="serviceDiv p-10 2xl:py-0" style={{minHeight:"330px" ,marginTop:"10px",width:"100%"}}>
                <div className="flex flex-col-reverse 2xl:flex-row">
                  <div className="flex flex-col 2xl:flex-row w-full justify-center 2xl:justify-start 2xl:w-7/12 2xl:ml-10">
                  
                  <Media query = {{maxWidth:1535}}>
                    <>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item border border-gray-200 bg-white" style={{borderRadius:"15px"}}>
                        <h2 class="accordion-header mb-0" id="headingOne">
                          <button class="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none" style={{borderRadius:"15px"}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <p style={{fontFamily:"SF Pro Bold", color:"#584a89"}}>Enterprise Resource Planning</p>
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample">
                          <div class="accordion-body py-4 px-5  ">
                            <p style={{fontFamily:"SF Pro", color:"#584a89",lineHeight:"22px"}} className="text-md"> Integrating all facets of an operation into a single application
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                    <div class="accordion mt-1" id="accordionExample" >
                      <div class="accordion-item border border-gray-200 bg-white" style={{borderRadius:"15px"}}>
                        <h2 class="accordion-header mb-0" id="headingTwo">
                          <button class="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none" style={{borderRadius:"15px"}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <p style={{fontFamily:"SF Pro Bold", color:"#584a89"}}>Robotic Process Automation </p>
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample">
                          <div class="accordion-body py-4 px-5  ">
                            <p style={{fontFamily:"SF Pro", color:"#584a89",lineHeight:"22px"}} className="text-md"> Business process automation with artificial intelligence
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    </>
                  </Media>

                  <Media query ={{minWidth:1536}}>
                    <>
                    <div className="w-full 2xl:w-3/12 flex justify-center 2xl:justify-start">
                    <div style={{maxWidth:"220px",width:"215px",minWidth:"210px",minHeight:"270px"}} className="flex flex-col justify-center servicesDiv">
                      <h1 style={{fontFamily:"SF Pro Bold",wordBreak:"break-word", color:"#FFFFFF",lineHeight:"22px"}} className="text-xl">
                         Enterprise <br />
                        <span>Resource</span>
                        <br />
                        <span>Planning (ERP)</span>
                      </h1>
                      <br />
                      <p style={{fontFamily:"SF Pro", color:"#F2F2F2",lineHeight:"22px"}} className="text-md"> Integrating all facets <br />
                        <span>of an operation into </span>
                        <br />
                        <span>a single application.</span>
                      </p>
                    </div>
                    </div>
                    <div style={{borderLeft:"2px solid #9a91c0", width:1, maxHeight:200,marginTop:"40px",paddingRight:"25px"}}></div>
                    <div className="w-full 2xl:w-3/12 flex justify-center 2xl:justify-start">
                    <div style={{maxWidth:"220px",width:"215px",minWidth:"200px",minHeight:"270px"}} className="flex flex-col justify-center servicesDiv">
                      <h1 style={{fontFamily:"SF Pro Bold",wordBreak:"break-word", color:"#FFFFFF",lineHeight:"22px"}} className="text-xl"> Robotic <br />
                        <span>Process</span>
                        <br />
                        <span>Automation (RPA)</span>
                      </h1>
                      <br />
                      <p style={{fontFamily:"SF Pro", color:"#F2F2F2",lineHeight:"22px"}} className="text-md"> Business Process <br />
                        <span>automation with</span>
                        <br />
                        <span>artificial intelligence.</span>
                      </p>
                    </div>
                    </div>
                    </>
                  </Media>

                  </div>
                  <div className="flex flex-col w-full justify-center 2xl:justify-center 2xl:w-5/12 2xl:ml-8 mb-3">
                <div style={{paddingRight:"5%"}}>
                    <div className="flex flex-row w-full justify-start 2xl:justify-start">
                      <img className="rounded-xl" style={{ maxHeight:70,maxWidth:65, height:'100%',width:"100%"}}src={customisedEnterpriseIcon}></img>
                      <div style={{width:"20px"}}></div>
                      <h1 style={{fontFamily:"SF Pro Bold", color:"#f5f5f5"}} className="text-2xl leading-8">Customized <br />
                        <span>Enterprise Solutions</span>
                      </h1>
                    </div>
                    <br />
                    <div className="flex flex-row w-full mb-3 justify-center">
                      <p style={{fontFamily:"SF Pro", color:"#f5f5f5"}}> Custom-designed Applications Such as Enterprise Resource Planning (ERP) and Robotic Process Automation (RPA) Systems.
                      </p>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="serviceDiv p-10 2xl:py-0 snap-center" style={{minHeight:"330px" ,marginTop:"10px",width:"100%"}}>
               <div className="flex flex-col-reverse 2xl:flex-row">
                <div className="flex flex-col 2xl:flex-row w-full justify-center 2xl:justify-start 2xl:w-7/12 2xl:ml-10">
                  <Media query = {{maxWidth:1535}}>
                      <>
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item border border-gray-200 bg-white" style={{borderRadius:"15px"}}>
                          <h2 class="accordion-header mb-0" id="headingThree">
                            <button class="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none" style={{borderRadius:"15px"}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              <p style={{fontFamily:"SF Pro Bold", color:"#584a89"}}>Seamless Assimilation & Transition</p>
                            </button>
                          </h2>
                          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body py-4 px-5  ">
                              <p style={{fontFamily:"SF Pro", color:"#584a89",lineHeight:"22px"}} className="text-md">Hollistic amalgamation & aggregation to accomplish an overarching function
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      </>
                    </Media>
                    <Media query = {{minWidth:1536}}>
                    <>
                      <div className="w-full 2xl:w-3/12 flex justify-center 2xl:justify-start">
                    <div style={{maxWidth:"220px",width:"215px",minWidth:"210px",minHeight:"270px"}} className="flex flex-col justify-center servicesDiv">
                      <h1 style={{fontFamily:"SF Pro Bold",wordBreak:"break-word", color:"#FFFFFF",lineHeight:"22px"}} className="text-xl 2xl:mt-5">
                      Seamless  <br />
                        <span>Assimilation & </span>
                        <br />
                        <span>Transition</span>
                      </h1>
                      <br />
                      <p style={{fontFamily:"SF Pro", color:"#F2F2F2",lineHeight:"22px"}} className="text-md"> Holistic amalgamation & aggregation to accomplish<br/>an overarching functionality that is more than the sum of<br/>its parts                       
                      </p>
                    </div>
                    </div>
                    </>
                    </Media>
                  </div>
                  <div className="flex flex-col w-full justify-center 2xl:w-5/12 2xl:ml-8 mb-3">
                  <div style={{paddingRight:"5%"}}>
                  <div className="flex flex-row w-full justify-start 2xl:justify-start" style={{marginTop:"5%"}}>
                      <img className="rounded-xl" style={{ maxHeight:70,maxWidth:65, height:'100%',width:"100%"}}src={systemIntegrationIcon}></img>
                      <div style={{width:"2%"}}></div>
                      <h1 style={{fontFamily:"SF Pro Bold", color:"#f5f5f5"}} className="text-2xl ml-3">Systems <br />
                        <span>Integration</span>
                      </h1>
                    </div>
                    <br />
                    <div className="flex flex-row w-full mb-3 justify-start 2xl:justify-start">
                      <p style={{fontFamily:"SF Pro", color:"#f5f5f5"}}> Linking Together Different Computing Systems And Software Applications as a Coordinated Whole.
                      </p>
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="serviceDiv p-10 2xl:py-0 snap-center" style={{minHeight:"330px" ,marginTop:"10px",width:"100%"}}>
               <div className="flex flex-col-reverse 2xl:flex-row">
                <div className="flex flex-col 2xl:flex-row w-full justify-center 2xl:justify-start 2xl:w-7/12 2xl:ml-10">
                    <Media query = {{maxWidth:1535}}>
                        <>
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item border border-gray-200 bg-white" style={{borderRadius:"15px"}}>
                            <h2 class="accordion-header mb-0" id="headingFour">
                              <button class="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none" style={{borderRadius:"15px"}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <p style={{fontFamily:"SF Pro Bold", color:"#584a89"}}>Company Site</p>
                              </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                              data-bs-parent="#accordionExample">
                              <div class="accordion-body py-4 px-5  ">
                                <p style={{fontFamily:"SF Pro", color:"#584a89",lineHeight:"22px"}} className="text-md">Hollistic amalgamation & aggregation to accomplish an overarching function
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="accordion mt-1" id="accordionExample">
                          <div class="accordion-item border border-gray-200 bg-white" style={{borderRadius:"15px"}}>
                            <h2 class="accordion-header mb-0" id="headingFive">
                              <button class="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none" style={{borderRadius:"15px"}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <p style={{fontFamily:"SF Pro Bold", color:"#584a89"}}>Website Revamp</p>
                              </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                              data-bs-parent="#accordionExample">
                              <div class="accordion-body py-4 px-5">
                                <p style={{fontFamily:"SF Pro", color:"#584a89",lineHeight:"22px"}} className="text-md">Modern sites for the modern world, where technology meets finesse.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="accordion mt-1" id="accordionExample">
                          <div class="accordion-item border border-gray-200 bg-white" style={{borderRadius:"15px"}}>
                            <h2 class="accordion-header mb-0" id="headingSix">
                              <button class="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none" style={{borderRadius:"15px"}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                <p style={{fontFamily:"SF Pro Bold", color:"#584a89"}}>E-Commerce Site</p>
                              </button>
                            </h2>
                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                              data-bs-parent="#accordionExample">
                              <div class="accordion-body py-4 px-5">
                                <p style={{fontFamily:"SF Pro", color:"#584a89",lineHeight:"22px"}} className="text-md">Enticing digital platforms for the 21st-century consumer.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        </>
                    </Media>
                    <Media query = {{minWidth:1536}}>
                    <>
                    <div className="w-full 2xl:w-3/12 flex justify-center 2xl:justify-start">
                    <div style={{maxWidth:"220px",width:"215px",minWidth:"210px",minHeight:"270px"}} className="flex flex-col justify-center servicesDiv">
                      <h1 style={{fontFamily:"SF Pro Bold",wordBreak:"break-word", color:"#FFFFFF",lineHeight:"22px"}} className="text-xl">
                      Company<br/>Site
                      </h1>
                      <br />
                      <p style={{fontFamily:"SF Pro", color:"#F2F2F2",lineHeight:"22px"}} className="text-md"> Powerful, strategic and<br/>
                      respledent digital<br/>
                      solutions
                      </p>
                    </div>
                    </div>
                    <div style={{borderLeft:"2px solid #9a91c0", width:1, maxHeight:200,marginTop:"40px",paddingRight:"25px"}}></div>
                    <div className="w-full 2xl:w-3/12 flex justify-center 2xl:justify-start">
                    <div style={{maxWidth:"220px",width:"215px",minWidth:"200px",minHeight:"270px"}} className="flex flex-col justify-center servicesDiv">
                      <h1 style={{fontFamily:"SF Pro Bold",wordBreak:"break-word", color:"#FFFFFF",lineHeight:"22px"}} className="text-xl">Website<br/>
                      Revamp
                      </h1>
                      <br />
                      <p style={{fontFamily:"SF Pro", color:"#F2F2F2",lineHeight:"22px"}} className="text-md"> Modern Sites for the<br/>
                      modern world, where<br/>
                      technology meets <br/>finesse.
                      </p>
                    </div>
                    </div>
                    <div style={{borderLeft:"2px solid #9a91c0", width:1, maxHeight:200,marginTop:"40px",paddingRight:"25px"}}></div>
                    <div className="w-full 2xl:w-3/12 flex justify-center 2xl:justify-start">
                    <div style={{maxWidth:"220px",width:"215px",minWidth:"200px",minHeight:"270px"}} className="flex flex-col justify-center servicesDiv">
                      <h1 style={{fontFamily:"SF Pro Bold",wordBreak:"break-word", color:"#FFFFFF",lineHeight:"22px"}} className="text-xl">E-Commerce<br/>
                      Site
                      </h1>
                      <br />
                      <p style={{fontFamily:"SF Pro", color:"#F2F2F2",lineHeight:"22px"}} className="text-md">Enticing digital platforms<br/>
                      for the 21st-century<br/>
                      consumer.
                      </p>
                    </div>
                    </div>                    
                    </>
                    </Media>
                </div>
                <div className="flex flex-col w-full justify-center 2xl:w-5/12 2xl:ml-8 mb-3">
                  <div style={{paddingRight:"5%"}}>
                  <div className="flex flex-row w-full justify-start 2xl:justify-start" style={{marginTop:"5%"}}>
                    <img className="rounded-xl" style={{ maxHeight:70,maxWidth:65}}src={mobileDevelopmentIcon}></img>
                    <h1 style={{fontFamily:"SF Pro Bold", color:"#f5f5f5"}} className="text-2xl ml-3">Mobile <br />
                      <span>Development</span>
                    </h1>
                  </div>
                  <br />
                  <div>
                    <p style={{fontFamily:"SF Pro", color:"#f5f5f5"}}> Company Site, Website Revamp, E-commerce Site
                    </p>
                    </div></div>
                </div>
              </div>
            </div>

            <div className="serviceDiv p-10 snap-center 2xl:py-0" style={{minHeight:"330px" ,marginTop:"10px",width:"100%"}}>
            <div className="flex flex-col-reverse 2xl:flex-row">
                  <div className="flex flex-col 2xl:flex-row w-full justify-center 2xl:justify-start 2xl:w-7/12 2xl:ml-10">
                <Media query = {{maxWidth:1535}}>
                    <>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item border border-gray-200 bg-white mt-1" style={{borderRadius:"15px"}}>
                        <h2 class="accordion-header mb-0" id="headingSeven" >
                          <button class="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none" style={{borderRadius:"15px"}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            <p style={{fontFamily:"SF Pro Bold", color:"#584a89"}}>IT Consultancy</p>
                          </button>
                        </h2>
                        <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                          data-bs-parent="#accordionExample">
                          <div class="accordion-body py-4 px-5  ">
                            <p style={{fontFamily:"SF Pro", color:"#584a89",lineHeight:"22px"}} className="text-md"> Our expertise translates to your commerical success in Data Protection and Cybersecurity Advisory Services.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                    </div>
                    </>
                  </Media>
                <Media query = {{minWidth:1536}}>
                <>
                <div className="w-full 2xl:w-3/12 flex justify-center 2xl:justify-start">
                    <div style={{maxWidth:"220px",width:"215px",minWidth:"200px",minHeight:"270px"}} className="flex flex-col justify-center servicesDiv">
                      <h1 style={{fontFamily:"SF Pro Bold",wordBreak:"break-word", color:"#FFFFFF",lineHeight:"22px"}} className="text-xl">IT<br/>
                      Consultancy
                      </h1>
                      <br />
                      <p style={{fontFamily:"SF Pro", color:"#F2F2F2",lineHeight:"22px"}} className="text-md">Our expertise translates<br/>
                      to your commercial success in Data Protection and Cybersecurity Advisory Services.
                      </p>
                    </div>
                    </div>  
                </>
                </Media>
                </div>
                <div className="flex flex-col w-full justify-center 2xl:w-5/12 2xl:ml-8 mb-3">
                  <div style={{paddingRight:"5%"}}>
                  <div className="flex flex-row w-full justify-start 2xl:justify-start" style={{marginTop:"5%"}}>
                    <img alt="" className="rounded-xl" style={{ maxHeight:70,maxWidth:65, height:'100%',width:"100%"}}src={servicesIcon}></img>
                    <div style={{width:"2%"}}></div>
                    <h1 style={{fontFamily:"SF Pro Bold", color:"#f5f5f5"}} className="text-2xl ml-3">Consulting <br />
                      <span>Services</span>
                    </h1>
                  </div>
                  <br />
                  <div>
                    <p style={{fontFamily:"SF Pro", color:"#f5f5f5"}}>
                    </p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Quote />
          <div className ="snap-start"><Footer/></div>
          </div>
      </>
    );
  }
  
export default Business;
  